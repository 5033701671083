import {
  EnvironmentOutlined,
  FacebookOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import React, { FC } from "react";
import { ContactChannel } from "../../interfaces/contactUs.interface";

export interface ContactChannelLabelProps {
  channel?: ContactChannel;
  description?: string;
}

const IconMap: Record<ContactChannel, React.ReactNode> = {
  address: <EnvironmentOutlined />,
  email: <MailOutlined />,
  facebook: <FacebookOutlined />,
  fax: <PrinterOutlined />,
  telephone: <PhoneOutlined />,
  link: <LinkOutlined />,
};

const ContactChannelLabel: FC<ContactChannelLabelProps> = ({
  channel,
  description,
}: ContactChannelLabelProps) => {
  if (channel && description) {
    return (
      <Tooltip title={description}>
        <Typography.Text ellipsis>
          <Space>
            {IconMap[channel]}
            {description}
          </Space>
        </Typography.Text>
      </Tooltip>
    );
  }

  return <QuestionOutlined />;
};

export default ContactChannelLabel;
