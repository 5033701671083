const Component = (): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <h1 style={{ fontWeight: "bold" }}>404</h1>
      <h1 style={{ fontWeight: "bold" }}>PAGE NOT FOUND</h1>
    </div>
  );
};

export default Component;
