import { message, Alert } from "antd";
import "./style.css";

export type AlertType = "success" | "error" | "info" | "warning";

export interface AlertOption {
  type: AlertType;
  duration?: number;
  content: React.ReactNode;
  closable?: boolean;
}

const alert = ({
  type,
  duration = 5,
  content,
  closable = true,
}: AlertOption): void => {
  const key = `${Date.now()}`;

  const handleAlertClose = () => {
    message.destroy(key);
  };

  message.open({
    key,
    type,
    duration,
    content: (
      <Alert
        message={content}
        type={type}
        showIcon
        closable={closable}
        onClose={handleAlertClose}
      />
    ),
  });
};

export default alert;
