/* eslint-disable react/destructuring-assignment */
import { ColumnsType } from "antd/es/table";
import { Tag, Tooltip, Typography } from "antd";
import { User } from "../../../interfaces/user.interface";
import { formatFromISO } from "../../../libs/moment";

const defaultTextRender = (value: string) => {
  return (
    <Tooltip title={value}>
      <Typography.Text>{value}</Typography.Text>
    </Tooltip>
  );
};

const generateColumns = (): ColumnsType<User> => {
  const columns: ColumnsType<User> = [
    // {
    //   title: "LINE UID",
    //   dataIndex: "lineUserId",
    //   key: "lineUserId",
    //   render: defaultTextRender,
    // },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: defaultTextRender,
      width: "150px",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: defaultTextRender,
      width: "180px",
    },
    {
      title: "Personal ID / Tax ID",
      dataIndex: "userIDNumber",
      key: "userIDNumber",
      render: defaultTextRender,
      width: "160px",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      width: "120px",
      render(value: number) {
        if (value === 1) {
          return "บุคคลธรรมดา";
        }
        if (value === 2) {
          return "นิติบุคคล";
        }
        return null;
      },
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      key: "companyType",
      width: "280px",
      render(value: string[]) {
        if (!Array.isArray(value)) {
          return "";
        }

        return (
          <>
            {value.map((v, idx) => {
              return (
                <Tooltip key={idx} title={v}>
                  <Tag>{v}</Tag>
                </Tooltip>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: "180px",
      render: defaultTextRender,
    },
    {
      title: "LINE ID",
      dataIndex: "lineId",
      key: "lineId",
      width: "150px",
      render: defaultTextRender,
    },
    {
      title: "Phone",
      dataIndex: "telephone",
      key: "telephone",
      width: "120px",
      render: defaultTextRender,
    },
    {
      title: "Ever used",
      dataIndex: "hasPriorUse",
      key: "hasPriorUse",
      width: "100px",
      render(value: boolean) {
        return (
          <Tooltip title={value ? "YES" : "NO"}>
            <Tag
              style={{ width: 40, textAlign: "center", padding: 2 }}
              color={value ? "success" : "error"}
            >
              {value ? "YES" : "NO"}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Create Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "160px",
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromISO(value));
        }
        return null;
      },
    },
    {
      title: "Last Login",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "160px",
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromISO(value));
        }
        return null;
      },
    },
  ];

  return columns;
};

export default generateColumns;
