import {
  Announcement,
  CreateAnnouncementData,
  UpdateAnnouncementData,
} from "../../interfaces/announcement.interface";
import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import axios from "../../libs/axios";

export const getAnnouncements = async (
  option?: GeneralGetRequestOption
): Promise<Announcement[]> => {
  const response = await axios.get<Announcement[]>("/announcements", {
    params: option,
  });
  return response.data;
};

export const getAnnouncementById = async (
  id: number | string
): Promise<Announcement> => {
  const response = await axios.get<Announcement>(`/announcements/${id}`);
  return response.data;
};

export const countAnnouncement = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/announcements/count", {
    params: option,
  });
  return response.data;
};

export const createAnnouncement = async (
  data: CreateAnnouncementData
): Promise<Announcement> => {
  const response = await axios.post<Announcement>("/announcements", data);
  return response.data;
};

export const updateAnnouncement = async (
  id: number | string,
  data: Partial<UpdateAnnouncementData>
): Promise<Announcement> => {
  const response = await axios.put<Announcement>(`/announcements/${id}`, data);
  return response.data;
};

export const deleteAnnouncement = async (
  id: number | string
): Promise<Announcement> => {
  const response = await axios.delete<Announcement>(`/announcements/${id}`);
  return response.data;
};
