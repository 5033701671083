import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import { UserLog } from "../../interfaces/userLog.interface";
import axios from "../../libs/axios";

export const getUsers = async (
  option?: GeneralGetRequestOption
): Promise<UserLog[]> => {
  const response = await axios.get<UserLog[]>("/log-users", {
    params: option,
  });
  return response.data;
};

export const getUserById = async (id: number | string): Promise<UserLog> => {
  const response = await axios.get<UserLog>(`/log-users/${id}`);
  return response.data;
};

export const countUser = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/log-users/count", {
    params: option,
  });
  return response.data;
};

export const exportUserCSV = async (
  option?: GeneralGetRequestOption
): Promise<Blob> => {
  const response = await axios.get<Blob>("/users/exportUserLog/csv", {
    params: option,
    responseType: "blob",
  });

  return response.data;
};
