import { PrivacyPolicy } from "../../interfaces/privacyPolicy.interface";
import axios from "../../libs/axios";

export const getPrivacyPolicy = async (): Promise<PrivacyPolicy> => {
  const response = await axios.get<PrivacyPolicy>("/privacy-policy");
  return response.data;
};

export const updatePrivacyPolicy = async (
  data: PrivacyPolicy
): Promise<PrivacyPolicy> => {
  const response = await axios.put<PrivacyPolicy>("/privacy-policy", data);
  return response.data;
};
