import { PDPA } from "../../interfaces/pdpa.interface";
import axios from "../../libs/axios";

export const getPDPA = async (): Promise<PDPA> => {
  const response = await axios.get<PDPA>("/pdpa");
  return response.data;
};

export const updatePDPA = async (data: PDPA): Promise<PDPA> => {
  const response = await axios.put<PDPA>("/pdpa", data);
  return response.data;
};
