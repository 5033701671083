import { useCallback, useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "./store/actions";
import Login from "./pages/Login";
import Invitation from "./pages/Invitation";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import NotFound from "./pages/NotFound";
import type { RenewTokenCallback } from "./interfaces/authentication.interface";
import useAuthentication from "./hooks/useAuthentication";
import { generateRoutes } from "./libs/route";
import * as accessTokenManager from "./libs/accessTokenManager";
import alert from "./components/AlertMessage";
import HomePage from "./components/HomePage";
import routes from "./routes";
import "antd/dist/antd.css";

const App = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useAuthentication();
  const [haveRenewedToken, setHaveRenewedToken] = useState(false);

  const onRenewToken: RenewTokenCallback = useCallback(
    (error) => {
      setHaveRenewedToken(true);
      if (error) {
        alert({
          type: "error",
          content: "Your session has timed out. Please login again.",
        });
        dispatch(actions.logout());
        history.push("/login");
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    const token = accessTokenManager.get();
    if (!token) {
      setHaveRenewedToken(true);
      return;
    }

    dispatch(actions.renewToken(onRenewToken));
  }, [dispatch, onRenewToken]);

  useEffect(() => {
    if (authentication?.token) {
      dispatch(actions.updateProfile());
    }
  }, [dispatch, authentication?.token]);

  if (!haveRenewedToken) return <></>;

  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/login" component={Login} />
      <Route exact path="/invitation" component={Invitation} />
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <Route exact path="/resetPassword" component={ResetPassword} />
      {generateRoutes(routes)}
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
