import axios, { AxiosError } from "axios";
import get from "lodash/get";
import setting from "../../config";
import * as accessTokenManager from "../accessTokenManager";

const instance = axios.create({
  baseURL: setting.BASE_API_URL,
});

instance.interceptors.request.use((config) => {
  const token = accessTokenManager.get();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default instance;

export const getErrorMessage = (
  error: Error | AxiosError,
  defaultValue: string
): string => {
  return get(error, "response.data.message", defaultValue);
};
