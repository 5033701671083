const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const loadedImageUrl = event.target?.result;
      if (!loadedImageUrl) {
        throw new Error("Invalid file.");
      }

      const image = document.createElement("img");

      image.src =
        typeof loadedImageUrl === "string"
          ? loadedImageUrl
          : Buffer.from(loadedImageUrl).toString("base64");

      image.onload = () => {
        const { width, height } = image;
        resolve({ width, height });
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  });
};

export default getImageDimensions;
