import moment from "moment";
import type { Moment } from "moment";

export const formatFromISO = (
  datetime: string,
  format = "YYYY-MM-DD HH:mm:ss"
): string => {
  return moment(datetime).format(format);
};

export const formatFromThai = (
  datetime: string,
  format = "DD/MM/YYYY HH:mm"
): string => {
  return moment(datetime).format(format);
};

export const concatenateToISO = (
  date: Moment,
  time: Moment,
  keepOffset = true
): string => {
  const strDate = date.format("YYYY-MM-DD");
  const strTime = time.format("HH:mm:ss");
  const datetime = `${strDate} ${strTime}`;
  return moment(datetime).toISOString(keepOffset);
};
