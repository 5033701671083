import { blue, red } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Space, Switch, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FAQGroup } from "../../../interfaces/faq.interface";

export type GenerateColumnsOptions = {
  onClickItem: (index: number) => void | PromiseLike<void>;
  onSwitchChange: (index: number, value: boolean) => void | PromiseLike<void>;
  onDeleteItem: (index: number) => void | PromiseLike<void>;
};

const generateColumns = (
  options: GenerateColumnsOptions
): ColumnsType<FAQGroup> => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (value: string, record, index) => (
      <Typography.Text
        style={{ color: blue.primary, cursor: "pointer" }}
        onClick={() => {
          options.onClickItem(index);
        }}
      >
        {value}
      </Typography.Text>
    ),
  },
  {
    title: "Number of answer",
    key: "answerNumber",
    render(_, record) {
      return record.questions.length;
    },
  },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render(value: boolean, _, index) {
      return (
        <Switch
          checked={value}
          onChange={(checked) => options.onSwitchChange(index, checked)}
        />
      );
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, record: FAQGroup, index: number) => (
      <Space size="middle">
        <DeleteOutlined
          onClick={() => {
            Modal.confirm({
              title: "Delete Banner",
              content: `Are you sure you want to delete?`,
              icon: <DeleteOutlined />,
              onOk() {
                options.onDeleteItem(index);
              },
            });
          }}
          style={{ color: red.primary }}
        />
      </Space>
    ),
  },
];

export default generateColumns;
