import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Empty, Row, Spin, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useParams } from "react-router-dom";
import { isInteger } from "lodash";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import DraggableBodyRow from "../../../components/DraggableBodyRow";
import { FAQ, FAQQuestion } from "../../../interfaces/faq.interface";
import { getFAQ, updateFAQ } from "../../../api/faq";
import alert from "../../../components/AlertMessage";
import generateColumns from "./column";
import CreationModal, { FAQQuestionCreationForm } from "./CreationModal";
import EditModal, { FAQQuestionEditForm } from "./EditModal";

export interface PageParams {
  index: string;
}

const Page = () => {
  const params = useParams<PageParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [creationModalVisible, setCreationModalVisible] =
    useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [faq, setFaq] = useState<FAQ>({ groups: [] });
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const groupIndex = useMemo<number>(() => {
    if (isInteger(Number(params.index))) {
      return Number(params.index);
    }
    return -1;
  }, [params.index]);

  const shouldRender = useMemo<boolean>(() => {
    return groupIndex !== -1;
  }, [groupIndex]);

  const faqItems = useMemo<FAQQuestion[]>(() => {
    if (faq) {
      if (faq.groups && faq.groups[groupIndex]) {
        return faq.groups[groupIndex].questions;
      }
    }
    return [];
  }, [faq, groupIndex]);

  const questionToEdit = useMemo(() => {
    if (typeof selectedIndex === "number") {
      return faq.groups[groupIndex].questions[selectedIndex] || null;
    }
    return null;
  }, [faq, groupIndex, selectedIndex]);

  const handleItemChange = useCallback(
    (items: FAQQuestion[]) => {
      setLoading(true);

      faq.groups[groupIndex].questions = items;

      updateFAQ({
        ...faq,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update FAQ.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [faq, groupIndex]
  );

  const handleClickItem = useCallback((index: number) => {
    setSelectedIndex(index);
    setEditModalVisible(true);
  }, []);

  const handleSwitchChange = useCallback(
    (index: number, value: boolean) => {
      setLoading(true);

      faq.groups[groupIndex].questions[index].isActive = value;

      updateFAQ({
        ...faq,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update FAQ.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [faq, groupIndex]
  );

  const handleQuestionCreationSubmit = useCallback(
    (values: FAQQuestionCreationForm) => {
      setLoading(true);

      faq.groups[groupIndex].questions.push({ ...values });

      updateFAQ({
        ...faq,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to add new question.",
          });
        })
        .finally(() => {
          setCreationModalVisible(false);
          setLoading(false);
        });
    },
    [faq, groupIndex]
  );

  const handleQuestionEditSubmit = useCallback(
    (index: number, values: FAQQuestionEditForm) => {
      setLoading(true);

      faq.groups[groupIndex].questions[index] = values;

      updateFAQ({
        ...faq,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update question.",
          });
        })
        .finally(() => {
          setEditModalVisible(false);
          setLoading(false);
        });
    },
    [faq, groupIndex]
  );

  const handleDeleteItem = useCallback(
    (index: number) => {
      setLoading(true);

      faq.groups[groupIndex].questions.splice(index, 1);

      updateFAQ({
        ...faq,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to delete question.",
          });
        })
        .finally(() => {
          setCreationModalVisible(false);
          setLoading(false);
        });
    },
    [faq, groupIndex]
  );

  const columns = useMemo(() => {
    return generateColumns({
      onClickItem: handleClickItem,
      onSwitchChange: handleSwitchChange,
      onDeleteItem: handleDeleteItem,
    });
  }, [handleClickItem, handleDeleteItem, handleSwitchChange]);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = faqItems[dragIndex];
      handleItemChange(
        update(faqItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [faqItems, handleItemChange]
  );

  useEffect(() => {
    setLoading(true);
    getFAQ()
      .then((result) => {
        setFaq(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch FAQ.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Breadcrumb
        title={faq.groups[groupIndex]?.title}
        items={[
          { title: "FAQ", location: "/faq" },
          { title: "Questions", location: "" },
        ]}
      />
      <Spin spinning={loading}>
        <ContentContainer>
          {shouldRender ? (
            <>
              <Row style={{ marginBottom: 20 }}>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  span={24}
                  sm={{ span: 10, offset: 14 }}
                  md={{ span: 12, offset: 12 }}
                >
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    ghost
                    onClick={() => {
                      setCreationModalVisible(true);
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <DndProvider backend={HTML5Backend}>
                <Table
                  style={{ overflowX: "scroll" }}
                  columns={columns}
                  rowKey="id"
                  dataSource={faqItems}
                  pagination={false}
                  components={{
                    body: {
                      row: DraggableBodyRow,
                    },
                  }}
                  onRow={(_, index) => {
                    const attr = {
                      index,
                      moveRow,
                    };
                    return attr as React.HTMLAttributes<unknown>;
                  }}
                />
              </DndProvider>
              <CreationModal
                loading={loading}
                visible={creationModalVisible}
                onOk={handleQuestionCreationSubmit}
                onCancel={() => {
                  setCreationModalVisible(false);
                }}
              />
              <EditModal
                loading={loading}
                visible={editModalVisible}
                itemIndex={selectedIndex}
                data={questionToEdit || null}
                onOk={handleQuestionEditSubmit}
                onCancel={() => {
                  setEditModalVisible(false);
                }}
              />
            </>
          ) : (
            <Empty />
          )}
        </ContentContainer>
      </Spin>
    </>
  );
};

export default WithAuthenticated(Page);
