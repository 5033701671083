import { Layout, Image, Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useMemo, useEffect } from "react";
import qs from "querystring";
import WithNonAuthenticated from "../../../components/WithNonAuthenticated";
import logo from "../../../assets/base-logo.svg";
import { resetPassword } from "../../../api/admin";
import { ResetPasswordForm } from "../../../interfaces/admin.interface";
import alert from "../../../components/AlertMessage";

const Component = (): JSX.Element => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const code = useMemo(() => {
    const params = qs.parse(location.search.slice(1));
    return params.code as string;
  }, [location]);

  useEffect(() => {
    if (!code) {
      history.push("/login");
    }
  }, [code, history]);

  const onFinish = (values: ResetPasswordForm) => {
    resetPassword({
      password: values.password,
      resetPasswordToken: code,
    })
      .then(() => {
        alert({
          type: "success",
          duration: 4,
          content: "Reset password successfully.",
        });
        history.push("/login");
      })
      .catch(() => {
        alert({
          type: "error",
          duration: 4,
          content: "The reset token has expired or is invalid.",
        });
      });
  };

  return (
    <Layout.Content className="LoginForm">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image preview={false} width={250} src={logo} />
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required.",
            },
            () => ({
              validator(_, value) {
                const lowercase = new RegExp("(?=.*[a-z])[a-z]");
                const uppercase = new RegExp("(?=.*[A-Z])[A-Z]");
                const number = new RegExp("(?=.*[0-9])[0-9]");
                if (value) {
                  if (value.length < 8) {
                    return Promise.reject(new Error("The value is too short."));
                  }
                  if (!lowercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one lowercase character."
                      )
                    );
                  }
                  if (!uppercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one uppercase character."
                      )
                    );
                  }
                  if (!number.test(value)) {
                    return Promise.reject(
                      new Error("Password must contain at least one number.")
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            placeholder="New password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirmation"
          rules={[
            {
              required: true,
              message: "Confirm password is required.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match.")
                );
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Confirm new password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Reset password
          </Button>
        </Form.Item>
      </Form>
    </Layout.Content>
  );
};

export default WithNonAuthenticated(Component);
