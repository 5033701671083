import { FC } from "react";

export interface ComponentProps {
  style?: React.CSSProperties;
}

const Component: FC<ComponentProps> = ({ style }: ComponentProps) => {
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 24, ...style }}>
      <div style={{ height: 600, display: "flex" }}>
        <p
          style={{
            margin: "auto",
            fontSize: 32,
            color: "#B9B9B9",
          }}
        >
          -- No Permission --
        </p>
      </div>
    </div>
  );
};

export default Component;
