/* eslint-disable react/destructuring-assignment */
import { ColumnsType } from "antd/es/table";
import { Tag, Tooltip, Typography } from "antd";
import { UserLog } from "../../../interfaces/userLog.interface";
import { formatFromISO, formatFromThai } from "../../../libs/moment";

const defaultTextRender = (value: string) => {
  return (
    <Tooltip title={value}>
      <Typography.Text>{value}</Typography.Text>
    </Tooltip>
  );
};

const generateColumns = (): ColumnsType<UserLog> => {
  const columns: ColumnsType<UserLog> = [
    {
      title: "Username",
      dataIndex: "fullname",
      key: "fullname",
      width: "20%",
      render: defaultTextRender,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      width: "20%",
      render(value: number) {
        if (value === 1) {
          return "บุคคลธรรมดา";
        }
        if (value === 2) {
          return "นิติบุคคล";
        }
        return null;
      },
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      key: "companyType",
      width: "20%",
      render(value: string[]) {
        if (!Array.isArray(value)) {
          return "";
        }

        return (
          <>
            {value.map((v, idx) => {
              return (
                <Tooltip key={idx} title={v}>
                  <Tag>{v}</Tag>
                </Tooltip>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Pagename",
      dataIndex: "pagename",
      key: "pagename",
      width: "20%",
      render: defaultTextRender,
    },
    {
      title: "Timestamp",
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromThai(value));
        }
        return null;
      },
    },
  ];

  return columns;
};

export default generateColumns;
