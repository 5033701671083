import { StrapiFileObject } from "./strapi.interface";

export enum ContactChannel {
  address = "address",
  telephone = "telephone",
  fax = "fax",
  email = "email",
  facebook = "facebook",
  link = "link",
}

export interface Contact {
  channel: string;
  label: string;
  description: string;
}

export interface ContactUs {
  contactChannels: Contact[];
  reportChannels: Contact[];
  mapImage: StrapiFileObject;
  mapEmbedCode: string;
}
