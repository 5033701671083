/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Switch,
  Row,
  Col,
  Radio,
} from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { RuleObject } from "antd/lib/form";
import {
  createAdmin,
  updateAdmin,
  getAdminsById,
  deleteAdmin,
  getRoles,
} from "../../../api/admin";
import {
  AdminCreationForm,
  UrlParamsDetailPage,
  Role,
} from "../../../interfaces/admin.interface";
import alert from "../../../components/AlertMessage";
import WithAuthenticated from "../../../components/WithAuthenticated";
import useAuthentication from "../../../hooks/useAuthentication";
import InvitationCard from "../../../components/InvitationCard";
import ContentContainer from "../../../components/ContentContainer";
import ContentContainerNoRole from "../../../components/ContentContainerNoRole";
import Breadcrumb from "../../../components/Breadcrumb";

export type PageType = "Create" | "Edit" | "View";

const getFilteredRoles = (roles: Array<Role>): Array<Role> => {
  return roles.filter((role) => ["strapi-super-admin"].includes(role.code));
};

const Container = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<UrlParamsDetailPage>();
  const authentication = useAuthentication();
  const [form] = Form.useForm<AdminCreationForm>();
  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [registrationToken, setRegistrationToken] = useState<string>();

  const authenticationId = useMemo(() => {
    return authentication?.user?.id;
  }, [authentication]);

  useEffect(() => {
    if (location.pathname === `/admin/edit/${id}`) {
      if (authenticationId === Number(id)) {
        history.replace(`/admin/detail/${id}`);
      }
    }
  }, [authenticationId, history, id, location.pathname]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Promise.all([getAdminsById(id), getRoles()])
        .then(([foundAdmin, foundRoles]) => {
          setRegistrationToken(foundAdmin.data.registrationToken);
          setRoles(getFilteredRoles(foundRoles.data));
          form.setFieldsValue({
            firstname: foundAdmin.data.firstname,
            lastname: foundAdmin.data.lastname,
            email: foundAdmin.data.email,
            isActive: foundAdmin.data.isActive,
            rolesId: foundAdmin.data.roles[0].id,
          });
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      getRoles()
        .then((foundRoles) => {
          setRoles(getFilteredRoles(foundRoles.data));
        })
        // .catch(() => {
        //  alert({
        //    type: "error",
        //    content: "Failed to fetch data555",
        //  });
        // })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form, id]);

  let Content = ContentContainer;
  if (authentication?.user?.roles[0].name !== "Super Admin") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Content = ContentContainerNoRole;
  }

  const shouldDisableEditButton = useMemo(() => {
    if (authenticationId === Number(id)) {
      return true;
    }
    return false;
  }, [authenticationId, id]);

  const shouldDisableDeleteButton = useMemo(() => {
    return shouldDisableEditButton;
  }, [shouldDisableEditButton]);

  const pageType: PageType = useMemo(() => {
    if (location.pathname === `/admin/detail/${id}`) return "View";
    if (location.pathname === `/admin/edit/${id}`) return "Edit";
    return "Create";
  }, [location, id]);

  const isDetailPage = useMemo(() => {
    return pageType === "View";
  }, [pageType]);

  const shouldRenderActiveInput = useMemo(() => {
    return pageType !== "Create";
  }, [pageType]);

  const shouldRenderSubmitButton = useMemo(() => {
    return pageType === "Create" || pageType === "Edit";
  }, [pageType]);

  const shouldRenderEditButton = useMemo(() => {
    return pageType === "View";
  }, [pageType]);

  const shouldRenderDeleteButton = useMemo(() => {
    return pageType === "View" || pageType === "Edit";
  }, [pageType]);

  const onFinish = (values: AdminCreationForm) => {
    const superAdminRole = roles.find(
      (role) => role.code === "strapi-super-admin"
    );

    if (!superAdminRole) {
      alert({
        type: "error",
        content: "Failed to fetch role data.",
      });
    } else if (pageType === "Edit") {
      setLoading(true);
      updateAdmin(id, {
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        roles: [values.rolesId],
        isActive: values.isActive || false,
      })
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Update admin successfully",
          });
          setLoading(false);
          history.push("/admin/list");
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to edit admin",
          });
          setLoading(false);
        });
    } else {
      setLoading(true);
      createAdmin({
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        roles: [values.rolesId],
      })
        .then((result) => {
          alert({
            type: "success",
            duration: 5,
            content: "Create admin successfully",
          });
          setLoading(false);
          history.push(`/admin/detail/${result.data.id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to create admin",
          });
          setLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: "Delete Administrator",
      content: "Are you sure you want to delete?",
      icon: <DeleteOutlined />,
      onOk() {
        setLoading(true);
        deleteAdmin({ ids: [+id] }).then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Delete administrator successfully",
          });
          setLoading(false);
          history.push("/admin/list");
        });
      },
    });
  };

  function isEmptyOrSpaces(str: { match: (arg0: RegExp) => null } | null) {
    return str === null || str.match(/^ *$/) !== null;
  }

  const validationFirstname = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (isEmptyOrSpaces(value)) {
      return callback("First name is required.");
    }
    return callback();
  };

  const validationLastname = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (isEmptyOrSpaces(value)) {
      return callback("Last name is required.");
    }
    return callback();
  };

  const validationEmail = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (isEmptyOrSpaces(value)) {
      return callback("Email is required.");
    }
    return callback();
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="Administrator"
        items={[
          { title: "Administrator", location: "/admin/list" },
          { title: pageType, location: "" },
        ]}
      />
      <Content>
        <InvitationCard registrationToken={registrationToken} />
        <Form
          form={form}
          labelCol={{ span: 2 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 8,
            },
          }}
          onFinish={onFinish}
          autoComplete="off"
          className="adminpage"
        >
          <Form.Item
            className="FormCustom"
            label="First name"
            name="firstname"
            rules={[{ required: true, validator: validationFirstname }]}
          >
            <Input
              placeholder="First name"
              bordered={!isDetailPage}
              readOnly={isDetailPage}
              style={{ marginLeft: 10 }}
            />
          </Form.Item>
          <Form.Item
            className="FormCustom"
            label="Last name"
            name="lastname"
            rules={[{ required: true, validator: validationLastname }]}
          >
            <Input
              placeholder="Last name"
              bordered={!isDetailPage}
              readOnly={isDetailPage}
              style={{ marginLeft: 10 }}
            />
          </Form.Item>
          <Form.Item
            className="FormCustom"
            label="Email"
            name="email"
            rules={[{ required: true, validator: validationEmail }]}
          >
            <Input
              placeholder="Email"
              bordered={!isDetailPage}
              readOnly={isDetailPage}
              style={{ marginLeft: 10 }}
            />
          </Form.Item>
          <Form.Item
            className="FormCustom"
            label="Role"
            name="rolesId"
            rules={[{ required: true, message: "*Please select role." }]}
          >
            <Radio.Group disabled={isDetailPage} style={{ marginLeft: 10 }}>
              <Radio value={1}>Admin</Radio>
              <Radio value={2}>Staff</Radio>
            </Radio.Group>
          </Form.Item>
          {shouldRenderActiveInput && (
            <Form.Item
              className="FormCustom"
              style={{ display: "flex" }}
              label="Active"
              name="isActive"
              valuePropName="checked"
            >
              <Switch disabled={isDetailPage} />
            </Form.Item>
          )}
          <Row style={{ marginTop: 30 }}>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 10,
              }}
            >
              {shouldRenderSubmitButton && (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
              {shouldRenderEditButton && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/admin/edit/${id}`);
                  }}
                  disabled={shouldDisableEditButton}
                >
                  Edit
                </Button>
              )}
              <Button
                style={{ marginLeft: 5 }}
                htmlType="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              {shouldRenderDeleteButton && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="default"
                  onClick={handleClickDelete}
                  disabled={shouldDisableDeleteButton}
                  danger
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Content>
    </Spin>
  );
};

export default WithAuthenticated(Container);
