import { AboutUs } from "../../interfaces/aboutUs.interface";
import axios from "../../libs/axios";

export const getAboutUs = async (): Promise<AboutUs> => {
  const response = await axios.get<AboutUs>("/about-us");
  return response.data;
};

export const updateAboutUs = async (data: AboutUs): Promise<AboutUs> => {
  const response = await axios.put<AboutUs>("/about-us", data);
  return response.data;
};
