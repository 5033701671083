import urlToBlob from "../urlToBlob";

const urlToFile = async (
  url: string,
  filename: string,
  mimetype: string
): Promise<File> => {
  const blob = await urlToBlob(url);
  const file = new File([blob], filename, {
    type: mimetype,
  });
  return file;
};

export default urlToFile;
