import { Form, Input, Modal, Spin, Switch } from "antd";
import { FC, useEffect } from "react";
import TextEditor from "../../../components/TextEditor";
import { FAQQuestion } from "../../../interfaces/faq.interface";

export interface EditModalProps {
  loading: boolean;
  visible: boolean;
  itemIndex?: number;
  data: FAQQuestion | null;
  onOk: (index: number, values: FAQQuestionEditForm) => void;
  onCancel: () => void;
}

export interface FAQQuestionEditForm {
  question: string;
  answer: string;
  isActive: boolean;
}

const EditModal: FC<EditModalProps> = ({
  loading,
  visible,
  itemIndex,
  data,
  onOk,
  onCancel,
}: EditModalProps) => {
  const [form] = Form.useForm<FAQQuestionEditForm>();

  const handleFormSubmit = (values: FAQQuestionEditForm) => {
    if (typeof itemIndex === "number") {
      onOk(itemIndex, values);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form, visible]);

  return (
    <Modal
      title="Edit the Question"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel();
      }}
      confirmLoading={loading}
      cancelButtonProps={{
        loading,
      }}
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required!",
          }}
        >
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="answer" label="Answer" rules={[{ required: true }]}>
            <TextEditor />
          </Form.Item>
          <Form.Item
            name="isActive"
            label="Active"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditModal;
