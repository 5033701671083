import {
  CreateNewsData,
  News,
  UpdateNewsData,
} from "../../interfaces/news.interface";
import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import axios from "../../libs/axios";

export const getNews = async (
  option?: GeneralGetRequestOption
): Promise<News[]> => {
  const response = await axios.get<News[]>("/press-releases", {
    params: option,
  });
  return response.data;
};

export const getNewsById = async (id: number | string): Promise<News> => {
  const response = await axios.get<News>(`/press-releases/${id}`);
  return response.data;
};

export const countNews = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/press-releases/count", {
    params: option,
  });
  return response.data;
};

export const createNews = async (data: CreateNewsData): Promise<News> => {
  const response = await axios.post<News>("/press-releases", data);
  return response.data;
};

export const updateNews = async (
  id: number | string,
  data: Partial<UpdateNewsData>
): Promise<News> => {
  const response = await axios.put<News>(`/press-releases/${id}`, data);
  return response.data;
};

export const deleteNews = async (id: number | string): Promise<News> => {
  const response = await axios.delete<News>(`/press-releases/${id}`);
  return response.data;
};
