import { useMemo, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Drawer, Layout, Grid } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "./index.css";

const { Content, Sider } = Layout;

function Component({
  children,
  fullscreen,
}: InferProps<typeof Component.propTypes>): JSX.Element {
  const screens = Grid.useBreakpoint();
  const [isToggled, setToggled] = useState<boolean>(false);
  const [isBroken, setBroken] = useState<boolean>(false);

  const marginLeftOfContentSection = useMemo((): 0 | 200 => {
    if (screens.xxl) return 200;
    if (screens.xl) return 200;
    if (screens.lg) return 200;
    if (screens.md) return 0;
    if (screens.sm) return 0;
    return 0;
  }, [screens]);

  return (
    <Layout>
      <Layout.Header
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 2,
          padding: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <Navbar
          broken={isBroken}
          onClickOpen={() => {
            setToggled(true);
          }}
        />
      </Layout.Header>
      <Layout>
        <Drawer
          placement="left"
          closable={false}
          visible={isToggled}
          className="sider-mobile"
          bodyStyle={{ padding: 0 }}
          contentWrapperStyle={{ width: 260 }}
          onClose={() => {
            setToggled(false);
          }}
        >
          <Sidebar broken={isBroken} />
        </Drawer>
        <Sider
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={isToggled}
          className="sider-desktop"
          style={{
            position: "fixed",
            height: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
          onBreakpoint={(broken) => {
            setBroken(broken);
            setToggled(false);
          }}
        >
          <Sidebar broken={isBroken} />
        </Sider>
        <Content
          style={{
            padding: fullscreen ? 0 : "24px 16px",
            marginTop: 64,
            marginLeft: marginLeftOfContentSection,
            minHeight: "calc(100vh - 64px)",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

Component.defaultProps = {
  fullscreen: false,
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
};

export default Component;
