import { blue, red } from "@ant-design/colors";
import { Image, Modal, Space, Switch, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { News } from "../../../interfaces/news.interface";
import { StrapiImage } from "../../../interfaces/strapi.interface";
import { NewsCategory } from "../../../interfaces/newsCategory.interface";

export type GenerateColumnsOptions = {
  onClickDetail: (data: News, index: number) => void;
  onClickEdit: (data: News, index: number) => void;
  onClickDelete: (data: News, index: number) => void;
  onUpdateIsActive: (updatedValue: boolean, data: News, index: number) => void;
  onUpdateIsHighlight: (
    updatedValue: boolean,
    data: News,
    index: number
  ) => void;
};

const generateColumns = (
  options: GenerateColumnsOptions
): ColumnsType<News> => {
  const columns: ColumnsType<News> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text: string, record, index) => (
        <Typography.Text
          style={{ color: blue.primary, cursor: "pointer" }}
          onClick={() => {
            options.onClickDetail(record, index);
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Category",
      dataIndex: "pressReleaseCategory",
      key: "pressReleaseCategory",
      render: (category: NewsCategory) => {
        const { title } = category;
        return <Typography.Text>{title}</Typography.Text>;
      },
    },
    {
      title: "Cover Image",
      dataIndex: "coverImage",
      key: "coverImage",
      render: (data: StrapiImage) => {
        const { url } = data;
        return (
          <Image
            style={{
              maxHeight: 100,
            }}
            src={url}
          />
        );
      },
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (data: boolean, record, index) => {
        return (
          <Switch
            checked={data}
            onChange={(checked) => {
              options.onUpdateIsActive(checked, record, index);
            }}
          />
        );
      },
    },
    {
      title: "Highlight",
      dataIndex: "isHighlight",
      key: "isHighlight",
      render: (data: boolean, record, index) => {
        return (
          <Switch
            checked={data}
            onChange={(checked) => {
              options.onUpdateIsHighlight(checked, record, index);
            }}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: blue.primary }}
            onClick={() => {
              options.onClickEdit(record, index);
            }}
          />
          <DeleteOutlined
            style={{ color: red.primary }}
            onClick={() => {
              Modal.confirm({
                title: "Delete News",
                content: `Are you sure you want to delete?`,
                icon: <DeleteOutlined />,
                onOk() {
                  options.onClickDelete(record, index);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  return columns;
};

export default generateColumns;
