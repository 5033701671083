import {
  CreateBroadcastData,
  Broadcast,
  UpdateBroadcastData,
  UserBroadcast,
} from "../../interfaces/broadcast.interface";
import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import { User } from "../../interfaces/user.interface";
import axios from "../../libs/axios";

export const getBroadcast = async (
  option?: GeneralGetRequestOption
): Promise<Broadcast[]> => {
  const response = await axios.get<Broadcast[]>("/broadcasts", {
    params: option,
  });
  return response.data;
};

export const getBroadcastById = async (
  id: number | string
): Promise<Broadcast> => {
  const response = await axios.get<Broadcast>(`/broadcasts/${id}`);
  return response.data;
};

export const countBroadcast = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/broadcasts/count", {
    params: option,
  });
  return response.data;
};

export const createBroadcast = async (
  data: CreateBroadcastData
): Promise<Broadcast> => {
  const response = await axios.post<Broadcast>("/broadcasts", data);
  return response.data;
};

export const updateBroadcast = async (
  id: number | string,
  data: Partial<UpdateBroadcastData>
): Promise<Broadcast> => {
  const response = await axios.put<Broadcast>(`/broadcasts/${id}`, data);
  return response.data;
};

export const deleteBroadcast = async (
  id: number | string
): Promise<Broadcast> => {
  const response = await axios.delete<Broadcast>(`/broadcasts/${id}`);
  return response.data;
};

export const getUsers = async (
  person: number | 0,
  corporation: number | 0
): Promise<number> => {
  const response = await axios.get<number>(
    `/users/count?userType=${person}&userType=${corporation}`
  );
  return response.data;
};

export const getUsersbyCompany = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.post<number>("/users/companyType/count", {
    params: option,
  });
  return response.data;
};

export const getUsersbyPerson = async (): Promise<number> => {
  const response = await axios.post<number>("/users/personType/count");
  return response.data;
};

export const getUsersAll = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/users/count?userType_null=false", {
    params: option,
  });
  return response.data;
};
