import { Editor } from "@ckeditor/ckeditor5-core";
import UploadAdapter from "./UploadAdapter";

function UploadAdapterPlugin(editor: Editor): void {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

export default UploadAdapterPlugin;
