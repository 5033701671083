import { FC } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Plugin } from "@ckeditor/ckeditor5-core";
import UploadAdapterPlugin from "./UploadAdapterPlugin";

export interface TextEditorProps {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

const TextEditor: FC<TextEditorProps> = ({
  value,
  onChange,
  disabled = false,
}: TextEditorProps) => {
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          extraPlugins: [UploadAdapterPlugin as unknown as typeof Plugin],
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange?.(data);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default TextEditor;
