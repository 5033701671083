import WithAuthenticated from "../../components/WithAuthenticated";
import RichMenuSettingPanel from "../../components/RichMenuSettingPanel";
import ContentContainer from "../../components/ContentContainer";
import Breadcrumb from "../../components/Breadcrumb";

const Container = () => {
  return (
    <>
      <Breadcrumb
        title="Rich Menu"
        items={[{ title: "Rich Menu", location: "" }]}
      />
      <ContentContainer>
        <RichMenuSettingPanel
          key="default"
          header="Default Rich menu"
          event="default"
        />
      </ContentContainer>
      <ContentContainer style={{ marginTop: 24 }}>
        <RichMenuSettingPanel
          key="after_register"
          header="Rich menu after bind account"
          event="after_register"
        />
      </ContentContainer>
    </>
  );
};

export default WithAuthenticated(Container);
