import type { AxiosError } from "axios";
import axios from "../../libs/axios";
import type {
  Admin,
  ListAdmin,
  FormDeleteAdmin,
  AdminResponse,
  AdminCreationRequestData,
  AdminRegistrationInfo,
  RequestInvitationForm,
  ResponseInvitationForm,
  ResponseRoleAdmin,
  ForgotPasswordForm,
  ResetPasswordForm,
  AdminUpdateRequestData,
} from "../../interfaces/admin.interface";
import type { GeneralGetRequestOption } from "../../interfaces/request.interface";

export const getAdmins = (
  option?: GeneralGetRequestOption
): Promise<ListAdmin> =>
  new Promise((resolve, reject) => {
    axios
      .get("/admin/users", { params: option })
      .then((result: { data: ListAdmin }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getAdminsById = (id: string | number): Promise<AdminResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/admin/users/${id}`)
      .then((result: { data: AdminResponse }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const createAdmin = (
  data: AdminCreationRequestData
): Promise<AdminResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/custom-admin/users", data)
      .then((result: { data: AdminResponse }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const updateAdmin = (
  id: number | string,
  data: AdminUpdateRequestData
): Promise<Admin> =>
  new Promise((resolve, reject) => {
    axios
      .put(`/admin/users/${id}`, data)
      .then((result: { data: Admin }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const deleteAdmin = (data: FormDeleteAdmin): Promise<Admin> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/users/batch-delete", data)
      .then((result: { data: Admin }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
export const getRegistrationInfo = (
  registrationToken: string
): Promise<AdminRegistrationInfo> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/admin/registration-info?registrationToken=${registrationToken}`)
      .then((result: { data: AdminRegistrationInfo }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const registerAdmin = (
  data: RequestInvitationForm
): Promise<ResponseInvitationForm> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/register", data)
      .then((result: { data: ResponseInvitationForm }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
export const getRoles = (): Promise<ResponseRoleAdmin> =>
  new Promise((resolve, reject) => {
    axios
      .get("/admin/roles")
      .then((result: { data: ResponseRoleAdmin }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const forgotPassword = (data: ForgotPasswordForm): Promise<void> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/forgot-password", data)
      .then(() => {
        resolve();
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const resetPassword = (data: ResetPasswordForm): Promise<void> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/reset-password", data)
      .then(() => {
        resolve();
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
