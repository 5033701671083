import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import { User } from "../../interfaces/user.interface";
import axios from "../../libs/axios";

export const getUsers = async (
  option?: GeneralGetRequestOption
): Promise<User[]> => {
  const response = await axios.get<User[]>("/users", {
    params: option,
  });
  return response.data;
};

export const getUserById = async (id: number | string): Promise<User> => {
  const response = await axios.get<User>(`/users/${id}`);
  return response.data;
};

export const countUser = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/users/count", {
    params: option,
  });
  return response.data;
};

export const exportUserCSV = async (
  option?: GeneralGetRequestOption
): Promise<Blob> => {
  const response = await axios.get<Blob>("/users/export/csv", {
    params: option,
    responseType: "blob",
  });

  return response.data;
};
