import { FC } from "react";
import { Route } from "react-router-dom";
import Layout from "../../components/Layout";

export type LayoutOption = {
  fullscreen?: boolean;
};

export type RouteConfig = {
  exact?: boolean;
  path: string;
  component: FC<unknown>;
  withLayout?: boolean;
  layoutOption?: LayoutOption;
};

export const generateRoutes = (
  configs: Array<RouteConfig>
): Array<JSX.Element> => {
  return configs.map((route, idx) => {
    return (
      <Route
        key={idx}
        exact={route.exact}
        path={route.path}
        render={() => {
          if (route.withLayout === undefined || route.withLayout) {
            return (
              <Layout fullscreen={route.layoutOption?.fullscreen}>
                <route.component />
              </Layout>
            );
          }
          return <route.component />;
        }}
      />
    );
  });
};
