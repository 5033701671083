import { FileLoader } from "@ckeditor/ckeditor5-upload/src/filerepository";
import { upload as uploadFile } from "../../api/upload";

class UploadAdapter {
  constructor(private readonly loader: FileLoader) {}

  // eslint-disable-next-line class-methods-use-this
  async upload(): Promise<{ default: string }> {
    const file = await this.loader.file;

    if (file) {
      const result = await uploadFile(file);
      return {
        default: result.url,
      };
    }

    throw new Error("File is not available.");
  }

  // eslint-disable-next-line class-methods-use-this
  abort(): void {}
}

export default UploadAdapter;
