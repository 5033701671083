/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Button,
  Image,
  Checkbox,
  Row,
  Col,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import WithNonAuthenticated from "../../components/WithNonAuthenticated";
import * as actions from "../../store/actions";
import type { LoginForm } from "../../interfaces/authentication.interface";
import "./index.css";
import logo from "../../assets/base-logo.svg";
import alert from "../../components/AlertMessage";

const Component = (): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [checkedRememberMe, setCheckedRememberMe] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onCheckRememberMe = (e: CheckboxChangeEvent) => {
    setCheckedRememberMe(e.target.checked);
  };

  const onFinish = (values: LoginForm) => {
    setLoading(true);
    const onLogin = (error: Error | null): void => {
      setLoading(false);
      if (error) {
        alert({
          type: "error",
          content: "Incorrect email address add/or password.",
        });
        return;
      }
      history.push("/");
    };

    dispatch(
      actions.login(
        {
          email: values.email,
          password: values.password,
          rememberMe: checkedRememberMe,
        },
        onLogin
      )
    );
  };

  return (
    <Spin spinning={loading}>
      <Layout.Content className="LoginForm">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image preview={false} width={250} src={logo} />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t("login.form.validate.required.email"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("login.form.label.email")}
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("login.form.validate.required.password"),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("login.form.label.password")}
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Checkbox
                checked={checkedRememberMe}
                onChange={onCheckRememberMe}
              >
                Remember me
              </Checkbox>
            </Col>
            <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <a
                onClick={() => {
                  history.push("/forgotPassword");
                }}
              >
                Forgot your password?
              </a>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t("login.button")}
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Spin>
  );
};

export default WithNonAuthenticated(Component);
