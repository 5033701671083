import { FC, useState, useEffect, useMemo } from "react";
import { Button, Col, Input, Row, Spin, Form, Typography } from "antd";
import urljoin from "url-join";
import RichMenuUploadInput from "../RichMenuUploadInput";
import {
  findDefaultRichMenu,
  findAfterRegisterRichMenu,
  createRichMenu,
} from "../../api/richMenu";
import { RichMenu, RichMenuEvent } from "../../interfaces/richmenu.interface";
import isJSON from "../../libs/isJSON";
import urlToBlob from "../../libs/urlToBlob";
import alert from "../AlertMessage";
import isURL from "../../libs/isURL";
import setting from "../../config";
import isBlobURL from "../../libs/isBlobURL";

export interface ComponentProps {
  key: string;
  header: string;
  event: RichMenuEvent;
}

export interface FormProperties {
  image: string;
  setting: string;
}

const Component: FC<ComponentProps> = ({ event }: ComponentProps) => {
  const [form] = Form.useForm<FormProperties>();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentRichMenu, setCurrentRichMenu] = useState<RichMenu>();

  const title = useMemo(() => {
    if (event === "default") {
      return "Default rich menu";
    }
    if (event === "after_register") {
      return "Rich menu after bound account";
    }
    return undefined;
  }, [event]);

  const getImageUrl = (text: string): string => {
    if (isURL(text)) {
      return text;
    }
    return urljoin(setting.BASE_API_URL || "", text);
  };

  useEffect(() => {
    Promise.all([
      (() => {
        if (event === "default") return findDefaultRichMenu();
        if (event === "after_register") return findAfterRegisterRichMenu();
        return null;
      })(),
    ])
      .then(([richMenu]) => {
        if (richMenu) {
          setCurrentRichMenu(richMenu);
          form.setFieldsValue({
            setting: JSON.stringify(richMenu.setting, null, 2),
            image: getImageUrl(richMenu.image.url),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form, event]);

  const handleFormFinish = async (values: FormProperties) => {
    setLoading(true);

    let blob = await urlToBlob(values.image);

    if (!isBlobURL(values.image)) {
      blob = new Blob([blob], {
        type: currentRichMenu?.image.mime,
      });
    }

    const formData = new FormData();

    formData.append(
      "data",
      JSON.stringify({
        event,
        setting: JSON.parse(values.setting),
      })
    );

    formData.append("files.image", blob);

    createRichMenu(formData)
      .then((result) => {
        setCurrentRichMenu(result);
        form.setFieldsValue({
          setting: JSON.stringify(result?.setting, null, 2),
          image: getImageUrl(result.image.url),
        });
        alert({
          type: "success",
          content: "Update Rich Menu successfully",
        });
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to update Rich Menu",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={handleFormFinish}>
        <Row>
          <Col span={12}>
            <Typography.Title level={5}>{title}</Typography.Title>
          </Col>
          <Col
            style={{
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
            span={12}
          >
            <Button htmlType="submit">Save</Button>
          </Col>
          <Col style={{ marginBottom: 10 }} span={24}>
            <Form.Item
              name="image"
              rules={[{ required: true, message: "Image is required." }]}
            >
              <RichMenuUploadInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="setting"
              rules={[
                { required: true, message: "Setting is required." },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (isJSON(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Input must be a JSON."));
                  },
                }),
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default Component;
