import { FAQ } from "../../interfaces/faq.interface";
import axios from "../../libs/axios";

export const getFAQ = async (): Promise<FAQ> => {
  const response = await axios.get<FAQ>("/faq");
  return response.data;
};

export const updateFAQ = async (faq: FAQ): Promise<FAQ> => {
  const response = await axios.put<FAQ>("/faq", faq);
  return response.data;
};
