import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Image, Upload } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import getBase64 from "../../libs/getBase64";
import "./style.css";

export interface SingleFileUploadInputProps {
  disabled?: boolean;
  value?: File;
  onChange?: (file?: File) => void;
}

const SingleImageUploadInput: FC<SingleFileUploadInputProps> = ({
  disabled = false,
  value,
  onChange,
}: SingleFileUploadInputProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [displayDeleteIcon, setDisplayDeleteIcon] = useState<boolean>(false);

  const clear = useCallback(() => {
    if (!disabled) {
      onChange?.(undefined);
    }
  }, [disabled, onChange]);

  useEffect(() => {
    if (value) {
      setLoading(true);
      getBase64(value)
        .then((result) => {
          setImageUrl(result);
        })
        .catch(() => {
          setImageUrl(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setImageUrl(undefined);
    }
  }, [value]);

  return (
    <Upload
      listType="picture-card"
      className="image-uploader"
      showUploadList={false}
      customRequest={() => {}}
      onChange={(info) => {
        if (info.file.originFileObj) {
          onChange?.(info.file.originFileObj);
        }
      }}
      accept="image/*"
      maxCount={1}
      disabled={!!imageUrl || disabled}
    >
      {imageUrl ? (
        <div
          className={disabled ? "" : "blur"}
          style={{ position: "relative", width: "100%", height: "100%" }}
          onMouseEnter={() => {
            setDisplayDeleteIcon(true);
          }}
          onMouseLeave={() => {
            setDisplayDeleteIcon(false);
          }}
        >
          {!disabled && displayDeleteIcon && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: 32,
                  zIndex: 2,
                  color: "#ffffff",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  clear();
                }}
              />
            </div>
          )}
          <div>
            <Image
              src={imageUrl}
              preview={false}
              onClick={(e) => {
                e.stopPropagation();
                clear();
              }}
            />
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 4 }}>Upload</div>
        </div>
      )}
    </Upload>
  );
};

export default SingleImageUploadInput;
