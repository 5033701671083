import { Table, Input, Row, Col, Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback } from "react";
import type { ColumnsType } from "antd/lib/table/interface";
import WithAuthenticated from "../../../components/WithAuthenticated";
import Breadcrumb from "../../../components/Breadcrumb";
import ContentContainer from "../../../components/ContentContainer";
import generateColumns from "./column";
import alert from "../../../components/AlertMessage";
import { Announcement } from "../../../interfaces/announcement.interface";
import {
  countAnnouncement,
  deleteAnnouncement,
  getAnnouncements,
  updateAnnouncement,
} from "../../../api/announcement";

const AnnouncementList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<Array<Announcement>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const handleClickDetail = useCallback(
    (data: Announcement) => {
      history.push(`/announcement/detail/${data.id}`);
    },
    [history]
  );

  const handleClickEdit = useCallback(
    (data: Announcement) => {
      history.push(`/announcement/edit/${data.id}`);
    },
    [history]
  );

  const handleClickDelete = useCallback((data: Announcement, index: number) => {
    setLoading(true);
    deleteAnnouncement(data.id)
      .then(() => {
        setDataSource((prev) => {
          prev.splice(index, 1);
          return [...prev];
        });
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to delete Announcement.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleUpdateIsActive = useCallback(
    (updatedValue: boolean, data: Announcement, index: number) => {
      setLoading(true);
      updateAnnouncement(data.id, {
        isActive: updatedValue,
      })
        .then((result) => {
          setDataSource((prev) => {
            // eslint-disable-next-line no-param-reassign
            prev[index] = result;
            return [...prev];
          });
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update Announcement.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const columns = useMemo<ColumnsType<Announcement>>(() => {
    return generateColumns({
      onClickDetail: handleClickDetail,
      onClickEdit: handleClickEdit,
      onClickDelete: handleClickDelete,
      onUpdateIsActive: handleUpdateIsActive,
    });
  }, [
    handleClickDelete,
    handleClickDetail,
    handleClickEdit,
    handleUpdateIsActive,
  ]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAnnouncements({
        _limit: pageSize,
        _start: (currentPage - 1) * pageSize,
        _sort: `created_at:desc`,
      }),
      countAnnouncement(),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage, pageSize]);

  const onSearch = (searchText: string) => {
    setLoading(true);
    Promise.all([
      getAnnouncements({
        _limit: pageSize,
        _start: (currentPage - 1) * pageSize,
        _q: searchText,
        _sort: `created_at:desc`,
      }),
      countAnnouncement({
        _q: searchText,
      }),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb
        title="Announcements"
        items={[{ title: "Announcement", location: "" }]}
      />
      <Spin spinning={loading}>
        <ContentContainer>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
              span={24}
              sm={14}
              md={12}
            >
              <Input.Search
                placeholder="Please Enter"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={10}
              md={12}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  history.push(`/announcement/create`);
                }}
              >
                Create Announcement
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              total: pageCount,
              pageSize,
              onChange: (page, pagesize) => {
                setCurrentPage(page);
                setPageSize(pagesize || 0);
              },
            }}
            style={{ overflowX: "scroll" }}
          />
        </ContentContainer>
      </Spin>
    </>
  );
};

export default WithAuthenticated(AnnouncementList);
