import { red } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";
import { Image, Modal, Space, Switch } from "antd";
import type { ColumnsType } from "antd/es/table";
import { BannerItem } from "../../../interfaces/banner.interface";
import { formatFromISO } from "../../../libs/moment";

export type GenerateColumnsOptions = {
  onSwitchChange: (index: number, value: boolean) => void | PromiseLike<void>;
  onDeleteItem: (index: number) => void | PromiseLike<void>;
};

const generateColumns = (
  options: GenerateColumnsOptions
): ColumnsType<BannerItem> => [
  {
    title: "Media",
    dataIndex: "media",
    key: "Media",
    render(_, record) {
      const height = 150;

      if (record.media) {
        const mediaType = record.media.mime.split("/")[0];

        switch (mediaType) {
          case "image": {
            return <Image height={height} src={record.media.url} />;
          }
          case "video": {
            return (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video height={height} controls>
                <source src={record.media.url} type={record.media.mime} />
              </video>
            );
          }
          default: {
            return <div style={{ height }} />;
          }
        }
      }

      if (record.externalUrl) {
        return (
          <iframe
            title="External Video"
            height={height}
            src={record.externalUrl}
          />
        );
      }

      return <div style={{ height }} />;
    },
  },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render(value: boolean, _, index) {
      return (
        <Switch
          checked={value}
          onChange={(checked) => options.onSwitchChange(index, checked)}
        />
      );
    },
  },
  {
    title: "Created At",
    dataIndex: ["media", "created_at"],
    key: "created_at",
    render(value: string) {
      if (value) {
        return formatFromISO(value);
      }
      return null;
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, record: BannerItem, index: number) => (
      <Space size="middle">
        <DeleteOutlined
          onClick={() => {
            Modal.confirm({
              title: "Delete Banner",
              content: `Are you sure you want to delete?`,
              icon: <DeleteOutlined />,
              onOk() {
                options.onDeleteItem(index);
              },
            });
          }}
          style={{ color: red.primary }}
        />
      </Space>
    ),
  },
];

export default generateColumns;
