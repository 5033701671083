export const get = (): string | null => {
  return (
    window.localStorage.getItem("token") ||
    window.sessionStorage.getItem("token") ||
    null
  );
};

export const set = (value: string, rememberMe = false): void => {
  if (rememberMe) {
    return window.localStorage.setItem("token", value);
  }
  return window.sessionStorage.setItem("token", value);
};

export const clear = (): void => {
  window.localStorage.clear();
  window.sessionStorage.clear();
};

export const isRememberMe = (): boolean => {
  if (window.localStorage.getItem("token")) {
    return true;
  }
  return false;
};
