import * as actionTypes from "../actionTypes";
import * as authenticationAPI from "../../api/authentication";
import * as accessTokenManager from "../../libs/accessTokenManager";
import type {
  DispatchType,
  LoginCallback,
  LogoutCallback,
  RenewTokenCallback,
} from "../../interfaces/authentication.interface";
import type { LoginRequest } from "../../interfaces/authenticationAPI.interface";

export interface LoginData extends LoginRequest {
  rememberMe: boolean;
}

export const login =
  (args: LoginData, callback?: LoginCallback) =>
  (dispatch: DispatchType): void => {
    authenticationAPI
      .login({
        email: args.email,
        password: args.password,
      })
      .then((result) => {
        accessTokenManager.set(result.data.token, args.rememberMe);
        dispatch({
          type: actionTypes.AUTHENTICATION_LOGIN,
          payload: result.data,
        });
        callback?.(null, result);
      })
      .catch((error) => {
        callback?.(error);
      });
  };

export const logout =
  (callback?: LogoutCallback) =>
  (dispatch: DispatchType): void => {
    accessTokenManager.clear();
    dispatch({
      type: actionTypes.AUTHENTICATION_LOGOUT,
    });
    callback?.(null);
  };

export const renewToken =
  (callback?: RenewTokenCallback) =>
  (dispatch: DispatchType): void => {
    const token = accessTokenManager.get();

    if (!token) {
      callback?.(new Error("No token."));
      return;
    }

    authenticationAPI
      .renewToken({
        token,
      })
      .then((result) => {
        accessTokenManager.set(
          result.data.token,
          accessTokenManager.isRememberMe()
        );
        dispatch({
          type: actionTypes.AUTHENTICATION_RENEW_TOKEN,
          payload: result.data,
        });
        callback?.(null, result);
      })
      .catch((error) => {
        callback?.(error);
      });
  };

export const updateProfile =
  () =>
  (dispatch: DispatchType): void => {
    Promise.all([
      authenticationAPI.getProfile(),
      authenticationAPI.getPermissions(),
    ]).then(([profile, permissions]) => {
      dispatch({
        type: actionTypes.AUTHENTICATION_UPDATE_PROFILE,
        payload: {
          user: profile.data,
          permissions: permissions.data,
        },
      });
    });
  };
