import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import type {
  MenuClickEventHandler,
  SelectEventHandler,
} from "rc-menu/lib/interface";

export type ILink = {
  to: string;
  label: string;
};

export type MenuChildItem = {
  key: string;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  link: ILink;
};

export type MenuItem = {
  key: string;
  icon?: React.ReactNode;
  title?: string;
  link?: ILink;
  childrens?: Array<MenuChildItem>;
};

export type MenuSetting = Array<MenuItem>;

export const generateMenus = (menus: MenuSetting): Array<JSX.Element> => {
  return menus.map((item) => {
    if (item.childrens && item.childrens.length > 0) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.title}>
          {item.childrens.map((child) => {
            return (
              <Menu.Item key={[item.key, child.key].join("@")}>
                <Link to={child.link.to}>{child.link.label}</Link>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        <Link to={item.link?.to || "#"}>{item.link?.label || item.title}</Link>
      </Menu.Item>
    );
  });
};

export interface ComponentProps {
  menus: MenuSetting;
  defaultSelectedKeys?: Array<string>;
  defaultOpenKeys?: Array<string>;
  selectedKeys?: Array<string>;
  openKeys?: Array<string>;
  onClick?: MenuClickEventHandler;
  onSelect?: SelectEventHandler;
  onOpenChange: (openKeys: React.Key[]) => void;
}

const Component: FC<ComponentProps> = ({
  menus,
  defaultSelectedKeys,
  defaultOpenKeys,
  selectedKeys,
  openKeys,
  onClick,
  onSelect,
  onOpenChange,
}: ComponentProps) => {
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onClick={onClick}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
    >
      {generateMenus(menus)}
    </Menu>
  );
};

export default Component;
