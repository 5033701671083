import { FC, useMemo } from "react";
import { Breadcrumb, Spin, Typography } from "antd";
import { useHistory } from "react-router-dom";
import capitalize from "lodash/capitalize";

const { Title } = Typography;

export interface BreadcrumbItem {
  title: string;
  location: string;
}

export interface ComponentProps {
  title: string;
  items: Array<BreadcrumbItem>;
  capitalizeTitle?: boolean;
}

const Component: FC<ComponentProps> = ({
  title,
  items,
  capitalizeTitle = true,
}: ComponentProps) => {
  const history = useHistory();

  const renderTitle = useMemo<string>(() => {
    if (capitalizeTitle) {
      return capitalize(title);
    }
    return title;
  }, [capitalizeTitle, title]);

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: 24,
          marginBottom: 24,
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item
            href=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            Home
          </Breadcrumb.Item>
          {items?.map((item, index) => {
            const itemProps =
              index !== items.length - 1 ? { href: item.location } : {};
            return (
              <Breadcrumb.Item
                key={index}
                {...itemProps}
                onClick={(e) => {
                  e.preventDefault();
                  if (item.location !== "") history.push(item.location);
                }}
              >
                {item.title}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <Title level={4} style={{ paddingTop: 16 }}>
          {renderTitle ? (
            <Typography.Text>{renderTitle}</Typography.Text>
          ) : (
            <Spin spinning />
          )}
        </Title>
      </div>
    </>
  );
};

export default Component;
