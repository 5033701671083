const getBase64 = (img: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.addEventListener("error", () =>
      reject(new Error("Failed to get Base64 from File."))
    );
    reader.readAsDataURL(img);
  });
};

export default getBase64;
