import { Form, Input, Modal, Spin, Switch } from "antd";
import { FC } from "react";
import TextEditor from "../../../components/TextEditor";

export interface CreationModalProps {
  loading: boolean;
  visible: boolean;
  onOk: (values: FAQQuestionCreationForm) => void;
  onCancel: () => void;
}

export interface FAQQuestionCreationForm {
  question: string;
  answer: string;
  isActive: boolean;
}

const CreationModal: FC<CreationModalProps> = ({
  loading,
  visible,
  onOk,
  onCancel,
}: CreationModalProps) => {
  const [form] = Form.useForm<FAQQuestionCreationForm>();

  const handleFormSubmit = (values: FAQQuestionCreationForm) => {
    onOk(values);
  };

  return (
    <Modal
      title="Add a new Question"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel();
      }}
      confirmLoading={loading}
      cancelButtonProps={{
        loading,
      }}
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required!",
          }}
        >
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="answer" label="Answer" rules={[{ required: true }]}>
            <TextEditor />
          </Form.Item>
          <Form.Item
            name="isActive"
            label="Active"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreationModal;
