import * as actionTypes from "../actionTypes";
import type {
  AuthenticationState,
  AuthenticationAction,
} from "../../interfaces/authentication.interface";

const INITIAL_STATE: AuthenticationState = {};

const reducer = (
  state: AuthenticationState = INITIAL_STATE,
  action: AuthenticationAction
): AuthenticationState => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_LOGIN: {
      return {
        ...state,
        token: action.payload?.token,
      };
    }
    case actionTypes.AUTHENTICATION_RENEW_TOKEN: {
      return {
        ...state,
        token: action.payload?.token,
      };
    }
    case actionTypes.AUTHENTICATION_UPDATE_PROFILE: {
      return {
        ...state,
        user: action.payload?.user,
        permissions: action.payload?.permissions,
      };
    }
    case actionTypes.AUTHENTICATION_LOGOUT: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;
