import { RouteConfig } from "./libs/route";
import * as Administrator from "./pages/Administrator";
import Profile from "./pages/Profile";
import RichMenu from "./pages/RichMenu";
import BannerList from "./pages/Banner/List";
import * as FAQ from "./pages/FAQ";
import * as News from "./pages/News";
import * as NewsCategory from "./pages/NewsCategory";
import * as Announcement from "./pages/Announcement";
import * as User from "./pages/User";
import ContactUs from "./pages/ContactUs";
import FooterSetting from "./pages/FooterSetting";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PDPA from "./pages/PDPA";
import * as Broadcast from "./pages/Broadcast";
import * as UserLog from "./pages/UserLog";

const routes: Array<RouteConfig> = [
  {
    exact: true,
    path: "/LogUser",
    component: UserLog.List,
  },
  {
    exact: true,
    path: "/broadcast",
    component: Broadcast.List,
  },
  {
    exact: true,
    path: "/broadcast/create",
    component: Broadcast.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/broadcast/detail/:id",
    component: Broadcast.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/broadcast/edit/:id",
    component: Broadcast.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/admin/list",
    component: Administrator.List,
  },
  {
    exact: true,
    path: "/admin/create",
    component: Administrator.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/admin/detail/:id",
    component: Administrator.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/admin/edit/:id",
    component: Administrator.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/user",
    component: User.List,
  },
  {
    exact: true,
    path: "/rich-menu",
    component: RichMenu,
  },
  {
    exact: true,
    path: "/profile",
    component: Profile,
  },
  {
    exact: true,
    path: "/banner",
    component: BannerList,
  },
  {
    exact: true,
    path: "/faq",
    component: FAQ.List,
  },
  {
    exact: true,
    path: "/faq/question/:index",
    component: FAQ.QuestionList,
  },
  {
    exact: true,
    path: "/contact-us",
    component: ContactUs,
  },
  {
    exact: true,
    path: "/about-us",
    component: AboutUs,
  },
  {
    exact: true,
    path: "/privacy",
    component: PrivacyPolicy,
  },
  {
    exact: true,
    path: "/pdpa",
    component: PDPA,
  },
  {
    exact: true,
    path: "/footer-setting",
    component: FooterSetting,
  },
  {
    exact: true,
    path: "/news-category/list",
    component: NewsCategory.List,
  },
  {
    exact: true,
    path: "/news-category/create",
    component: NewsCategory.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/news-category/detail/:id",
    component: NewsCategory.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/news-category/edit/:id",
    component: NewsCategory.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/news/list",
    component: News.List,
  },
  {
    exact: true,
    path: "/news/create",
    component: News.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/news/detail/:id",
    component: News.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/news/edit/:id",
    component: News.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/announcement/list",
    component: Announcement.List,
  },
  {
    exact: true,
    path: "/announcement/create",
    component: Announcement.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/announcement/detail/:id",
    component: Announcement.CreateOrUpdate,
  },
  {
    exact: true,
    path: "/announcement/edit/:id",
    component: Announcement.CreateOrUpdate,
  },
];

export default routes;
