import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Spin, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import has from "lodash/has";
import useAuthentication from "../../hooks/useAuthentication";
import logo from "../../assets/base-logo.svg";

export interface ComponentProps {
  broken: boolean;
  onClickOpen: () => void;
}

const Component: FC<ComponentProps> = ({
  broken,
  onClickOpen,
}: ComponentProps) => {
  const history = useHistory();
  const auth = useAuthentication();

  const fullname = useMemo(() => {
    if (!has(auth, "user.firstname") && !has(auth, "user.lastname")) {
      return auth?.user?.email;
    }
    return `${auth?.user?.firstname} ${auth?.user?.lastname}`;
  }, [auth]);

  if (broken) {
    return (
      <Row>
        <Col style={{ paddingLeft: 20 }} span={8}>
          <MenuOutlined
            style={{ fontSize: 17, cursor: "pointer" }}
            onClick={() => {
              onClickOpen();
            }}
          />
        </Col>
        <Col style={{ textAlign: "center" }} span={8}>
          <img
            className="logo-img"
            style={{ padding: 16, marginTop: -8 }}
            src={logo}
            alt=""
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: 20,
          }}
          span={8}
        />
      </Row>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img className="logo-img" src={logo} alt="" />
      </div>
      <div style={{ flex: 1 }} />
      <Spin spinning={!fullname} size="small">
        <Typography.Text
          style={{
            marginLeft: 40,
            marginRight: 20,
            cursor: "pointer",
            minWidth: "1ch",
            maxWidth: "20ch",
          }}
          onClick={() => {
            history.push("/profile");
          }}
          ellipsis
        >
          {fullname}
        </Typography.Text>
      </Spin>
    </div>
  );
};

export default Component;
