/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useMemo, useCallback, useReducer } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Row,
  Col,
  Select,
  Space,
  Radio,
  Checkbox,
  DatePicker,
  TimePicker,
  Card,
} from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { capitalize } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { RuleObject } from "antd/lib/form";
import alert from "../../../components/AlertMessage";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import SingleImageUploadInput from "../../../components/SingleImageUploadInput";
import { upload } from "../../../api/upload";
import { StrapiImage } from "../../../interfaces/strapi.interface";
import { Broadcast } from "../../../interfaces/broadcast.interface";
import {
  createBroadcast,
  getBroadcastById,
  updateBroadcast,
  getUsersAll,
  getUsersbyPerson,
  getUsersbyCompany,
} from "../../../api/broadcast";
import { concatenateToISO } from "../../../libs/moment";
import isJSON from "../../../libs/isJSON";

export type PageType = "create" | "edit" | "detail";

export interface BroadcastCreationForm {
  userTypeCorporation: string[];
  userType: any;
  LineOaAccount: string;
  campaignName: string;
  campaignDetails: string;
  targetGroup: string[];
  sendDateType: number;
  dateTimeSpecify: string;
  messageTypeText?: string;
  messageTypeJson?: string[];
  messageTypeImage?: File;
  Draft: boolean;
  numberRecipients: number;
  status: string;
  messageBroadcasts: any;
  RecipientsType: number;
  sendDate: Date;
  sendTime: Date;
}

const Page = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<any>();
  const { id } = useParams<Record<"id", string>>();
  const [form] = Form.useForm<BroadcastCreationForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [broadcast, setBroadcast] = useState<Broadcast>();
  const [shouldReUpload, setShouldReUpload] = useState<boolean>(false);
  const [person, setPerson] = useState<boolean>(false);
  const [corporation, setCorporation] = useState<boolean>(false);
  const [datePicker, setDatePicker] = useState<Moment>();
  const [timePicker, setTimePicker] = useState<Moment>();
  const [valueUser, setUserValue] = useState<number>();
  const [valueDate, setDateValue] = useState<number>();
  const [valueMessage, setMessageValue] = useState(1);
  const [userType, setuserType] = useState([{}]);
  const [typeCorporation, setTypeCorporation] = useState([]);
  const [selectedTime, setSelectedTime] = useState(moment());
  const [countUserAll, setCountUserAll] = useState<number>(0);
  const [countUserCorporation, setCountUserCorporation] = useState<number>(0);
  const [countUserPerson, setCountUserPerson] = useState<number>(0);
  const [messageBroadcastsArray, setmessageBroadcasts] = useState([]);
  const [statusData, setStatusData] = useState("Draft");
  const [countSum, setcountSum] = useState<number | undefined>(0);
  const [gg, forceUpdate] = useReducer((x) => x + 1, 0);

  const disabledHours = () => {
    if (
      moment(datePicker).startOf("day").toString() ===
      moment().startOf("day").toString()
    ) {
      const hours = [];
      const currentHour = moment().hour();

      for (let i = 0; i <= 24; i += 1) {
        if (i < currentHour) {
          hours.push(i);
        }
      }
      return hours;
    }
    return [];
  };

  const disabledMinutes = (selectedHour: number) => {
    if (
      moment(datePicker).startOf("day").toString() ===
      moment().startOf("day").toString()
    ) {
      const minutes = [];
      const currentMinute = moment().minute();
      if (selectedHour === moment().hour()) {
        for (let i = 0; i <= 60; i += 1) {
          if (i < currentMinute) {
            minutes.push(i);
          }
        }
      }
      return minutes;
    }
    return [];
  };

  const onSelect = (time: any) => {
    if (time.isAfter(moment())) {
      setSelectedTime(moment());
      return;
    }

    setSelectedTime(time);
  };

  const personFun = (e: any) => {
    setPerson(e.target.checked);
    if (e.target.checked === true) {
      setLoading(true);
      getUsersbyPerson()
        .then((result) => {
          setCountUserPerson(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch user data.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCountUserPerson(0);
    }
  };

  const corporationFun = (e: any) => {
    setCorporation(e.target.checked);
    if (e.target.checked === true) {
      form.setFieldsValue({ userTypeCorporation: [] });
    }
    if (e.target.checked === true) {
      setLoading(true);
      getUsersbyCompany()
        .then((result) => {
          setCountUserCorporation(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch user data.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCountUserCorporation(0);
    }
  };

  const onChangeTargetGroup = (e: any) => {
    setUserValue(e.target.value);
    if (e.target.value === 1) {
      form.setFieldsValue({ userTypeCorporation: [] });
      setCorporation(false);
      setPerson(false);
    }
    if (e.target.value === 1) {
      setLoading(true);
      Promise.all([
        getUsersAll({
          _limit: -1,
        }),
      ])
        .then(async ([result]) => {
          setCountUserAll(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch user data.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCountUserAll(0);
    }
  };
  const onChangeSendDate = (e: any) => {
    setDateValue(e.target.value);
  };

  const onChangeUserTypeCorporation = (companyType: any) => {
    if (companyType.length !== 0) {
      setLoading(true);
    }
    setTypeCorporation(companyType);
    getUsersbyCompany({ companyType })
      .then((result) => {
        setCountUserCorporation(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch user data.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeMessage = (e: any) => {
    setMessageValue(e.target.value);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 5; index++) {
      if (e.target.name === `messageType${index}`) {
        if (e.target.value === 1) {
          const text = document.getElementById(`text${index}`);
          // eslint-disable-next-line no-plusplus
          text?.classList.remove("show-form");
          const image = document.getElementById(`image${index}`);
          const json = document.getElementById(`json${index}`);
          image?.classList.add("show-form");
          json?.classList.add("show-form");
        }
        if (e.target.value === 2) {
          const image = document.getElementById(`image${index}`);
          // eslint-disable-next-line no-plusplus
          image?.classList.remove("show-form");
          const text = document.getElementById(`text${index}`);
          const json = document.getElementById(`json${index}`);
          text?.classList.add("show-form");
          json?.classList.add("show-form");
        }
        if (e.target.value === 3) {
          const json = document.getElementById(`json${index}`);
          // eslint-disable-next-line no-plusplus
          json?.classList.remove("show-form");
          const text = document.getElementById(`text${index}`);
          const image = document.getElementById(`image${index}`);
          text?.classList.add("show-form");
          image?.classList.add("show-form");
        }
      }
    }
  };

  const deleteF = (e: any) => {
    forceUpdate();
  };

  useEffect(() => {
    if (id || location.state) {
      setLoading(true);
      // const idState = JSON.stringify(location.state);
      let BroadcastById = "";
      if (id) {
        BroadcastById = id;
      } else {
        BroadcastById = location.state.data.id;
      }

      getBroadcastById(BroadcastById)
        .then(async (result) => {
          setBroadcast(result);
          for (
            let index = 0;
            index < result.messageBroadcasts.length;
            index += 1
          ) {
            if (result.messageBroadcasts[index].messageType === 1) {
              const text = document.getElementById(`text${index}`);
              // eslint-disable-next-line no-plusplus
              text?.classList.remove("show-form");
              const image = document.getElementById(`image${index}`);
              const json = document.getElementById(`json${index}`);
              image?.classList.add("show-form");
              json?.classList.add("show-form");
            }
            if (result.messageBroadcasts[index].messageType === 2) {
              const image = document.getElementById(`image${index}`);
              // eslint-disable-next-line no-plusplus
              image?.classList.remove("show-form");
              const text = document.getElementById(`text${index}`);
              const json = document.getElementById(`json${index}`);
              text?.classList.add("show-form");
              json?.classList.add("show-form");
            }
            if (result.messageBroadcasts[index].messageType === 3) {
              const json = document.getElementById(`json${index}`);
              // eslint-disable-next-line no-plusplus
              json?.classList.remove("show-form");
              const text = document.getElementById(`text${index}`);
              const image = document.getElementById(`image${index}`);
              text?.classList.add("show-form");
              image?.classList.add("show-form");
            }
          }
          if (id) {
            if (result.sendDateType === 2 && result.dateTimeSpecify != null) {
              setDatePicker(moment(result.dateTimeSpecify));
              setTimePicker(moment(result.dateTimeSpecify));
            }
            if (result.sendDateType === 2 && result.dateTimeSpecify != null) {
              form.setFieldsValue({
                sendDate: moment(result.dateTimeSpecify),
                sendTime: moment(result.dateTimeSpecify),
              });
            }
            setcountSum(result.numberRecipients);
            form.setFieldsValue({
              LineOaAccount: result.LineOaAccount,
              campaignName: result.campaignName,
              campaignDetails: result.campaignDetails,
              RecipientsType: result.RecipientsType,
              userTypeCorporation: result.targetGroup || [],
              sendDateType: result.sendDateType,
              dateTimeSpecify: result.dateTimeSpecify,
              messageTypeText: result.messageTypeText,
              messageTypeJson: result.messageTypeJson,
              Draft: result.Draft,
              numberRecipients: result.numberRecipients,
              status: result.status,
            });
          } else {
            setcountSum(location.state.data.numberRecipients);
            form.setFieldsValue({
              LineOaAccount: result.LineOaAccount,
              campaignName: result.campaignName,
              campaignDetails: result.campaignDetails,
              RecipientsType: result.RecipientsType,
              userTypeCorporation: result.targetGroup || [],
              sendDateType: result.sendDateType,
              messageTypeText: result.messageTypeText,
              messageTypeJson: result.messageTypeJson,
              Draft: result.Draft,
              numberRecipients: result.numberRecipients,
              status: result.status,
            });
          }
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form, id, location.state, gg]);

  const cachedValue = useMemo(() => {
    for (let index = 0; index < broadcast?.userType.length; index += 1) {
      if (broadcast?.userType[index].id === 1) {
        setPerson(true);
      }
      if (broadcast?.userType[index].id === 2) {
        setCorporation(true);
      }
    }
    setcountSum(broadcast?.numberRecipients);
    setDateValue(broadcast?.sendDateType);
    setUserValue(broadcast?.RecipientsType);
    setmessageBroadcasts(broadcast?.messageBroadcasts);
  }, [
    broadcast?.RecipientsType,
    broadcast?.messageBroadcasts,
    broadcast?.numberRecipients,
    broadcast?.sendDateType,
    broadcast?.userType,
  ]);

  const countSumValue = useMemo(() => {
    if (valueUser === 1) {
      setcountSum(countUserAll);
      setCountUserCorporation(0);
      setCountUserPerson(0);
    } else {
      setcountSum(countUserCorporation + countUserPerson);
    }
  }, [countUserAll, countUserCorporation, countUserPerson, valueUser]);

  const pageType: PageType = useMemo(() => {
    if (location.pathname === `/broadcast/detail/${id}`) return "detail";
    if (location.pathname === `/broadcast/edit/${id}`) return "edit";
    return "create";
  }, [location, id]);

  const isPage = useCallback(
    (type: PageType) => {
      return pageType === type;
    },
    [pageType]
  );

  const shouldRenderSubmitButton = useMemo(() => {
    return pageType === "create" || pageType === "edit";
  }, [pageType]);

  const shouldRenderEditButton = useMemo(() => {
    return pageType === "detail";
  }, [pageType]);

  const shouldRenderDeleteButton = useMemo(() => {
    return pageType === "detail" || pageType === "edit";
  }, [pageType]);

  const onFinish = async (values: BroadcastCreationForm) => {
    const dateFormat = moment(datePicker, "MM-DD-YYYY");
    const timeFormat = moment(timePicker, "HH:mm:ss");

    let actual = concatenateToISO(moment(), moment());
    if (valueDate === 2) {
      actual = concatenateToISO(dateFormat, timeFormat);
    }

    if (corporation === true) {
      userType.push({ id: 2 });
    }
    if (person === true) {
      userType.push({ id: 1 });
    }
    const filtered = userType.filter((el) => {
      return el !== null;
    });

    let status = "Sent";
    if (valueDate === 2) {
      status = "Schedule";
    }

    if (pageType === "edit") {
      setLoading(true);

      const messageBroadcasts = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < values.messageBroadcasts.length; index++) {
        let strapiImage: StrapiImage | undefined;
        if (values.messageBroadcasts[index].image) {
          // eslint-disable-next-line no-await-in-loop
          strapiImage = await upload(values.messageBroadcasts[index].image);
        }
        if (
          values.messageBroadcasts[index].messageType === 1 ||
          values.messageBroadcasts[index].messageType === undefined
        ) {
          messageBroadcasts.push({
            messageType: 1,
            text: values.messageBroadcasts[index].text,
          });
        } else if (values.messageBroadcasts[index].messageType === 2) {
          messageBroadcasts.push({
            messageType: 2,
            image: strapiImage === undefined ? null : strapiImage,
          });
        } else {
          messageBroadcasts.push({
            messageType: 3,
            json: JSON.parse(
              values.messageBroadcasts[index].json === undefined
                ? null
                : values.messageBroadcasts[index].json
            ),
          });
        }
      }

      updateBroadcast(id, {
        campaignName: values.campaignName,
        campaignDetails: values.campaignDetails,
        targetGroup: typeCorporation,
        dateTimeSpecify: actual,
        sendDateType: valueDate,
        RecipientsType: valueUser,
        status,
        userType: filtered.slice(1),
        messageBroadcasts,
        Draft: false,
        numberRecipients: countSum,
      })
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Update Broadcast successfully.",
          });
          setLoading(false);
          history.push(`/broadcast`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to update Broadcast.",
          });
          setLoading(false);
        });
    } else {
      setLoading(true);

      const messageBroadcasts = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < values.messageBroadcasts.length; index++) {
        let strapiImage: StrapiImage | undefined;
        if (values.messageBroadcasts[index].image) {
          // eslint-disable-next-line no-await-in-loop
          strapiImage = await upload(values.messageBroadcasts[index].image);
        }
        if (
          values.messageBroadcasts[index].messageType === 1 ||
          values.messageBroadcasts[index].messageType === undefined
        ) {
          messageBroadcasts.push({
            messageType: 1,
            text: values.messageBroadcasts[index].text,
          });
        } else if (values.messageBroadcasts[index].messageType === 2) {
          messageBroadcasts.push({
            messageType: 2,
            image: strapiImage === undefined ? null : strapiImage,
          });
        } else {
          messageBroadcasts.push({
            messageType: 3,
            json: JSON.parse(
              values.messageBroadcasts[index].json === undefined
                ? null
                : values.messageBroadcasts[index].json
            ),
          });
        }
      }
      createBroadcast({
        campaignName: values.campaignName,
        campaignDetails: values.campaignDetails,
        targetGroup: typeCorporation,
        dateTimeSpecify: actual,
        sendDateType: valueDate,
        RecipientsType: valueUser,
        status,
        userType: filtered.slice(1),
        messageBroadcasts,
        Draft: false,
        numberRecipients: countSum === undefined ? 0 : countSum,
      })
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Send broadcast successfully",
          });
          setLoading(false);
          history.push(`/broadcast`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to create broadcast.",
          });
          setLoading(false);
        });
    }
  };

  const saveDraft = async () => {
    form.validateFields(["campaignName"]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (await form.validateFields(["campaignName"])).campaignName;
    if (form.isFieldValidating("campaignName") === false) {
      const dateFormat = moment(datePicker, "MM-DD-YYYY");
      const timeFormat = moment(timePicker, "HH:mm:ss");

      let actual = concatenateToISO(moment(), moment());
      if (valueDate === 2) {
        actual = concatenateToISO(dateFormat, timeFormat);
      }
      if (corporation === true) {
        userType.push({ id: 2 });
      }
      if (person === true) {
        userType.push({ id: 1 });
      }
      const filtered = userType.filter((el) => {
        return el !== null;
      });
      const values = form.getFieldsValue(true);
      if (pageType === "edit") {
        setLoading(true);

        const messageBroadcasts = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < values.messageBroadcasts.length; index++) {
          let strapiImage: StrapiImage | undefined;
          if (values.messageBroadcasts[index].image) {
            // eslint-disable-next-line no-await-in-loop
            strapiImage = await upload(values.messageBroadcasts[index].image);
          }
          if (
            values.messageBroadcasts[index].messageType === 1 ||
            values.messageBroadcasts[index].messageType === undefined
          ) {
            messageBroadcasts.push({
              messageType: 1,
              text: values.messageBroadcasts[index].text,
            });
          } else if (values.messageBroadcasts[index].messageType === 2) {
            messageBroadcasts.push({
              messageType: 2,
              image: strapiImage === undefined ? null : strapiImage,
            });
          } else {
            messageBroadcasts.push({
              messageType: 3,
              json: JSON.parse(
                values.messageBroadcasts[index].json === undefined
                  ? null
                  : values.messageBroadcasts[index].json
              ),
            });
          }
        }

        updateBroadcast(id, {
          campaignName: values.campaignName,
          campaignDetails: values.campaignDetails,
          targetGroup: typeCorporation,
          dateTimeSpecify: actual,
          sendDateType: valueDate,
          RecipientsType: valueUser,
          status: "Draft",
          userType: filtered.slice(1),
          messageBroadcasts,
          Draft: false,
          numberRecipients: countSum,
        })
          .then(() => {
            alert({
              type: "success",
              duration: 5,
              content: "Update Draft Broadcast successfully.",
            });
            setLoading(false);
            history.push(`/broadcast`);
          })
          .catch(() => {
            alert({
              type: "error",
              duration: 5,
              content: "Failed to update Broadcast.",
            });
            setLoading(false);
          });
      } else {
        setLoading(true);

        const messageBroadcasts = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < values.messageBroadcasts.length; index++) {
          let strapiImage: StrapiImage | undefined;
          if (values.messageBroadcasts[index].image) {
            // eslint-disable-next-line no-await-in-loop
            strapiImage = await upload(values.messageBroadcasts[index].image);
          }
          if (
            values.messageBroadcasts[index].messageType === 1 ||
            values.messageBroadcasts[index].messageType === undefined
          ) {
            messageBroadcasts.push({
              messageType: 1,
              text: values.messageBroadcasts[index].text,
            });
          } else if (values.messageBroadcasts[index].messageType === 2) {
            messageBroadcasts.push({
              messageType: 2,
              image: strapiImage === undefined ? null : strapiImage,
            });
          } else {
            messageBroadcasts.push({
              messageType: 3,
              json: JSON.parse(
                values.messageBroadcasts[index].json === undefined
                  ? null
                  : values.messageBroadcasts[index].json
              ),
            });
          }
        }
        createBroadcast({
          campaignName: values.campaignName,
          campaignDetails: values.campaignDetails,
          targetGroup: typeCorporation,
          dateTimeSpecify: actual,
          sendDateType: valueDate,
          RecipientsType: valueUser,
          status: "Draft",
          userType: filtered.slice(1),
          messageBroadcasts,
          Draft: false,
          numberRecipients: countSum === undefined ? 0 : countSum,
        })
          .then(() => {
            alert({
              type: "success",
              duration: 5,
              content: "Draft broadcast successfully",
            });
            setLoading(false);
            history.push(`/broadcast`);
          })
          .catch(() => {
            alert({
              type: "error",
              duration: 5,
              content: "Failed to create broadcast.",
            });
            setLoading(false);
          });
      }
    }
  };

  function isEmptyOrSpaces(str: { match: (arg0: RegExp) => null } | null) {
    return str === null || str.match(/^ *$/) !== null || str === undefined;
  }

  const campaignName = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (value === undefined) {
      return callback("Broadcast Name is require.");
    }
    if (isEmptyOrSpaces(value)) {
      return callback("Broadcast Name is require.");
    }
    return callback();
  };

  const campaignDetails = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (value === undefined) {
      return callback("Broadcast Details is require.");
    }
    if (isEmptyOrSpaces(value)) {
      return callback("Broadcast Details is require.");
    }
    return callback();
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="Broadcast"
        items={[
          { title: "Broadcast", location: "/broadcast/list" },
          {
            title:
              // eslint-disable-next-line no-nested-ternary
              capitalize(pageType) === "Edit"
                ? "Edit Broadcast"
                : capitalize(pageType) || capitalize(pageType) === "Create"
                ? "Create Broadcast"
                : capitalize(pageType),
            location: "",
          },
        ]}
      />
      <ContentContainer>
        <Space style={{ fontSize: 20, fontWeight: 400 }}>
          Create Broadcast
        </Space>
        <Form
          initialValues={{
            messageBroadcasts:
              location.state === undefined
                ? [""]
                : location.state.messageBroadcastsArray,
          }}
          className="createBroadcast"
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 14,
            },
          }}
          onFinish={onFinish}
          autoComplete="off"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is require.",
          }}
        >
          <Row>
            <Col
              style={{
                borderRight: "1px solid #B9B9B9",
                padding: "20px 15% 20px 20px",
              }}
              xs={24}
              sm={{
                span: 17,
              }}
            >
              <Form.Item
                label="LINE OA Account"
                rules={[{ required: true }]}
                style={{ display: "block" }}
              >
                <Input readOnly disabled value="Patconnex" />
              </Form.Item>
              <Form.Item
                label="Broadcast Name"
                name="campaignName"
                style={{ display: "block", marginTop: 16 }}
                rules={[{ required: true, validator: campaignName }]}
              >
                <TextArea
                  showCount
                  maxLength={50}
                  placeholder="Placeholder"
                  className="textAreaBroadcast2"
                />
              </Form.Item>
              <Form.Item
                style={{ display: "block", marginTop: 40 }}
                label="Broadcast Details"
                name="campaignDetails"
                id="campaignDetails"
                rules={[{ required: true, validator: campaignDetails }]}
              >
                <TextArea
                  showCount
                  maxLength={2000}
                  placeholder="Placeholder"
                  className="textAreaBroadcast"
                />
              </Form.Item>
              <Form.Item
                style={{ display: "block", marginTop: 40 }}
                label="Recipients"
                name="RecipientsType"
                rules={[
                  {
                    required: true,
                    message: "Broadcast Recipients is require.",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChangeTargetGroup}
                  value={valueUser}
                  // defaultValue={1}
                  style={{ marginLeft: 16 }}
                >
                  <Space direction="vertical">
                    <Radio value={1}>All</Radio>
                    <Radio value={2}>Targeting</Radio>
                  </Space>
                  {valueUser === 2 ? (
                    <Form.Item
                      name="userType"
                      style={{ display: "block", marginLeft: 20 }}
                    >
                      <Checkbox
                        value={person}
                        checked={person}
                        onChange={personFun}
                        style={{ margin: "6px 0" }}
                      >
                        <div>บุคคล</div>
                      </Checkbox>
                      <br />
                      <Checkbox
                        value={corporation}
                        checked={corporation}
                        onChange={corporationFun}
                      >
                        <div>นิติบุคคล</div>
                      </Checkbox>
                    </Form.Item>
                  ) : null}
                  {corporation === true && valueUser === 2 ? (
                    <Form.Item
                      name="userTypeCorporation"
                      style={{ display: "block", marginLeft: 45 }}
                    >
                      <Select
                        showArrow
                        mode="tags"
                        placeholder="select one country"
                        style={{ minWidth: 500 }}
                        onChange={onChangeUserTypeCorporation}
                      >
                        <Select.Option
                          key={0}
                          value="ลูกค้ากลุ่มบริษัทสายเรือ/ตัวแทนสายเรือ"
                        >
                          กลุ่มบริษัทสายเรือ/ตัวแทนสายเรือ
                        </Select.Option>
                        <Select.Option
                          key={1}
                          value="เจ้าของสินค้า/ตัวแทนนำเข้า-ส่งออกสินค้า"
                        >
                          เจ้าของสินค้า/ตัวแทนนำเข้า-ส่งออกสินค้า
                        </Select.Option>
                        <Select.Option
                          key={2}
                          value="ผู้ประกอบการท่าเทียบเรือที่ ทลฉ."
                        >
                          ผู้ประกอบการท่าเทียบเรือที่ ทลฉ.
                        </Select.Option>
                        <Select.Option key={3} value="ผู้เช่าอสังหาริมทรัพย์">
                          ผู้เช่าอสังหาริมทรัพย์
                        </Select.Option>
                        <Select.Option key={4} value="อื่นๆ">
                          อื่นๆ
                        </Select.Option>
                        <Select.Option key={5} value="ไม่ระบุกลุ่มธุรกิจ">
                          ไม่ระบุกลุ่มธุรกิจ
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  ) : null}
                  {countSum === 0 ? (
                    <Form.Item
                      name="validator"
                      className="hide-Validator"
                      style={{
                        display: "block",
                        marginLeft: 45,
                      }}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (countSum === 0 || countSum === undefined) {
                              return Promise.reject(
                                new Error(
                                  "No members found in this group, please select target group again."
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="IP Pool"
                        style={{ width: "100%", display: "none" }}
                      />
                    </Form.Item>
                  ) : null}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Broadcast"
                style={{ display: "block", marginBottom: "-20px" }}
                rules={[
                  { required: true, message: "Broadcast Schedule is require." },
                ]}
                name="sendDateType"
              >
                <Radio.Group
                  onChange={onChangeSendDate}
                  value={valueDate}
                  // defaultValue={1}
                  style={{ marginLeft: 16, width: "100%" }}
                >
                  <Space direction="vertical">
                    <Radio value={1}>Send Now</Radio>
                    <Radio value={2}>Schedule</Radio>
                  </Space>
                  {valueDate === 2 ? (
                    <Form.Item
                      name="dateTimeSpecify"
                      style={{ display: "block" }}
                    >
                      <Row>
                        <Col style={{ width: "48%", marginRight: 8 }}>
                          <Form.Item
                            label="Date"
                            name="sendDate"
                            style={{ display: "block" }}
                            rules={[
                              {
                                required: true,
                                message: "Date is require.",
                              },
                            ]}
                          >
                            <DatePicker
                              format="MM-DD-YYYY"
                              onChange={(date) =>
                                setDatePicker(date || undefined)
                              }
                              disabledDate={(current) => {
                                return (
                                  moment().add(-1, "days") >= current ||
                                  moment().add(1, "month") <= current
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "48%" }}>
                          <Form.Item
                            label="Time"
                            name="sendTime"
                            style={{ display: "block" }}
                            rules={[
                              { required: true, message: "Time is require." },
                            ]}
                          >
                            <TimePicker
                              onChange={(time) =>
                                setTimePicker(time || undefined)
                              }
                              onSelect={onSelect}
                              disabledHours={disabledHours}
                              disabledMinutes={disabledMinutes}
                              format="HH:mm"
                              value={selectedTime}
                              style={{ width: "100%" }}
                              // use12Hours
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  ) : null}
                </Radio.Group>
              </Form.Item>
              <Form.List name="messageBroadcasts">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <>
                          <Space style={{ display: "flex", marginTop: 24 }}>
                            <p style={{ marginTop: 10, marginBottom: 6 }}>
                              Message Type
                              <span style={{ color: "#F04438" }}> *</span>
                            </p>
                            {fields.length > 1 ? (
                              <DeleteOutlined
                                className="dynamic-delete-button"
                                style={{
                                  margin: "0 8px",
                                  textAlign: "end",
                                  display: "block",
                                  color: "#F04438",
                                  fontSize: 17,
                                }}
                                onClick={() => {
                                  deleteF(field.name);
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </Space>
                          <Space
                            key={field.key}
                            style={{ display: "block", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              name={[field.name, "messageType"]}
                              fieldKey={[field.fieldKey, "messageType"]}
                            >
                              <Radio.Group
                                name={`messageType${field.fieldKey}`}
                                onChange={onChangeMessage}
                                value={valueMessage}
                                defaultValue={1}
                                style={{ marginLeft: 16, width: "100%" }}
                              >
                                <Space direction="vertical">
                                  <Radio value={1}>Text Edit</Radio>
                                  <Radio value={2}>Image</Radio>
                                  <Radio value={3}>JSON</Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                            <div id={`text${field.fieldKey}`}>
                              <Form.Item
                                {...field}
                                name={[field.name, "text"]}
                                fieldKey={[field.fieldKey, "text"]}
                                style={{ display: "block", marginLeft: 38 }}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      const text = document.getElementById(
                                        `text${field.fieldKey}`
                                      );
                                      if (text?.className === "show-form") {
                                        return Promise.resolve();
                                      }
                                      if (value === undefined) {
                                        return Promise.reject(
                                          new Error("Text Edit is require.")
                                        );
                                      }
                                      if (isEmptyOrSpaces(value)) {
                                        return Promise.reject(
                                          new Error("Text Edit is require.")
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <TextArea
                                  showCount
                                  maxLength={5000}
                                  className="textAreaBroadcast"
                                />
                              </Form.Item>
                            </div>
                            <div
                              id={`image${field.fieldKey}`}
                              className="show-form"
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "image"]}
                                fieldKey={[field.fieldKey, "image"]}
                                style={{ display: "block", marginLeft: 38 }}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      const image = document.getElementById(
                                        `image${field.fieldKey}`
                                      );
                                      if (image?.className === "show-form") {
                                        return Promise.resolve();
                                      }
                                      if (value === undefined) {
                                        return Promise.reject(
                                          new Error("Image is require.")
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <SingleImageUploadInput
                                  disabled={isPage("detail")}
                                  onChange={() => {
                                    setShouldReUpload(true);
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div
                              id={`json${field.fieldKey}`}
                              className="show-form"
                            >
                              <Form.Item
                                {...field}
                                style={{ display: "block", marginLeft: 38 }}
                                className="textAreaBroadcast"
                                name={[field.name, "json"]}
                                fieldKey={[field.fieldKey, "json"]}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      const json = document.getElementById(
                                        `json${field.fieldKey}`
                                      );
                                      if (json?.className === "show-form") {
                                        return Promise.resolve();
                                      }
                                      if (!value) {
                                        return Promise.reject(
                                          new Error("JSON is require.")
                                        );
                                      }
                                      if (isJSON(value)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error("Input must be a JSON")
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input.TextArea />
                              </Form.Item>
                            </div>
                          </Space>
                        </>
                      ))}
                      {fields.length <= 4 ? (
                        <Form.Item>
                          <Button
                            onClick={() => {
                              add();
                            }}
                            style={{
                              width: "90px",
                              marginTop: 36,
                              color: "#40a9ff",
                              borderColor: "#40a9ff",
                            }}
                          >
                            <PlusOutlined
                              style={{
                                fontSize: 9,
                                paddingTop: 6,
                                marginLeft: "-4px",
                                position: "absolute",
                              }}
                            />{" "}
                            Add
                          </Button>
                        </Form.Item>
                      ) : null}
                    </div>
                  );
                }}
              </Form.List>
            </Col>
            <Col
              xs={24}
              sm={{
                span: 7,
              }}
              style={{ textAlign: "center" }}
            >
              <Card
                title="Estimated Target"
                className="card-count"
                style={{
                  width: 268,
                  textAlign: "center",
                  margin: "auto",
                  borderColor: "#B9B9B9",
                }}
              >
                <p
                  style={{
                    fontSize: 64,
                    color: "#282828",
                    fontWeight: 400,
                    marginBottom: 16,
                  }}
                >
                  {countSum}
                </p>
                <p style={{ fontSize: 24, color: "#282828", fontWeight: 400 }}>
                  People
                </p>
              </Card>
            </Col>
          </Row>
          <Row style={{ padding: 20 }}>
            <Col>
              <Button
                style={{ marginRight: 15 }}
                htmlType="button"
                onClick={() => {
                  Modal.confirm({
                    title: "Cancel Broadcast",
                    content: `Are you sure you want to cancel broadcast?`,
                    icon: <CloseCircleOutlined style={{ color: "#E13C39" }} />,
                    onOk() {
                      history.push("/broadcast");
                    },
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ marginRight: 15 }}
                // eslint-disable-next-line no-sequences
                onClick={saveDraft}
              >
                Save Draft
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setStatusData("Send")}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
