import { StrapiFileObject } from "../../interfaces/strapi.interface";
import axios from "../../libs/axios";

// eslint-disable-next-line import/prefer-default-export
export const upload = async (file: File): Promise<StrapiFileObject> => {
  const formData = new FormData();
  formData.append("files", file);
  const response = await axios.post<StrapiFileObject[]>("/upload", formData);
  return response.data[0];
};
