import { Table, Input, Row, Col, Spin, Button } from "antd";
import { useState, useEffect, useMemo } from "react";
import type { ColumnsType } from "antd/lib/table/interface";
import { DownloadOutlined } from "@ant-design/icons";
import WithAuthenticated from "../../../components/WithAuthenticated";
import Breadcrumb from "../../../components/Breadcrumb";
import ContentContainer from "../../../components/ContentContainer";
import generateColumns from "./column";
import { UserLog } from "../../../interfaces/userLog.interface";
import { countUser, exportUserCSV, getUsers } from "../../../api/userLog";
import alert from "../../../components/AlertMessage";
import { formatFromISO } from "../../../libs/moment";
// import ContentContainerNoRole from "../../../components/ContentContainerNoRole";
// import useAuthentication from "../../../hooks/useAuthentication";

const UserList = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<Array<UserLog>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  // const authentication = useAuthentication();

  const Content = ContentContainer;

  // if (authentication?.user?.roles[0].name !== "Super Admin") {
  //  Content = ContentContainerNoRole;
  // }

  const columns = useMemo<ColumnsType<UserLog>>(() => {
    return generateColumns();
  }, []);

  const countSumValue = useMemo(() => {
    setLoading(true);
    Promise.all([
      getUsers({
        _limit: -1,
        _sort: `created_at:desc`,
      }),
      countUser(),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch user data.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSearch = (searchText: string) => {
    setLoading(true);
    Promise.all([
      getUsers({
        _limit: -1,
        // _start: (currentPage - 1) * pageSize,
        _q: searchText,
        _sort: `created_at:desc`,
      }),
      countUser({
        _q: searchText,
      }),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
        setCurrentPage(1);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch user data.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickExport = async () => {
    const result = await exportUserCSV({
      _limit: -1,
    });
    const url = window.URL.createObjectURL(result);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Log-User-${formatFromISO(Date())}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Breadcrumb
        title="Log User"
        items={[{ title: "Log User", location: "" }]}
      />
      <Spin spinning={loading}>
        <Content>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
              span={24}
              sm={14}
              md={12}
            >
              <Input.Search
                placeholder="Please Enter"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={10}
              md={12}
            >
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                ghost
                onClick={handleClickExport}
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            className="tableUser"
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              total: pageCount,
              current: currentPage,
              pageSize,
              onChange: (page, pagesize) => {
                setCurrentPage(page);
                setPageSize(pagesize || 0);
              },
            }}
            style={{ overflowX: "scroll" }}
          />
        </Content>
      </Spin>
    </>
  );
};

export default WithAuthenticated(UserList);
