import axios from "../../libs/axios";
import type {
  LoginRequest,
  LoginResponse,
  RenewTokenRequest,
  RenewTokenResponse,
  GetProfileResponse,
  GetPermissionsResponse,
} from "../../interfaces/authenticationAPI.interface";

export const login = (data: LoginRequest): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/login", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const renewToken = (
  data: RenewTokenRequest
): Promise<RenewTokenResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/admin/renew-token", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProfile = (): Promise<GetProfileResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get("/admin/users/me")
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getPermissions = (): Promise<GetPermissionsResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get("/admin/users/me/permissions")
      .then((result: { data: GetPermissionsResponse }) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
