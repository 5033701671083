import WithAuthenticated from "../../components/WithAuthenticated";
import Breadcrumb from "../../components/Breadcrumb";
import Profile from "../../components/Profile";
import "./index.css";

const Component = (): JSX.Element => {
  return (
    <>
      <Breadcrumb
        title="Profile"
        items={[{ title: "Profile Details", location: "" }]}
      />
      <Profile.DetailForm />
      <Profile.PasswordForm />
    </>
  );
};

export default WithAuthenticated(Component);
