import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Typography } from "antd";
import type { MenuClickEventHandler } from "rc-menu/lib/interface";
import { LogoutOutlined } from "@ant-design/icons";
import Menu from "../Menu";
import * as actions from "../../store/actions";
import alert from "../AlertMessage";
import useAuthentication from "../../hooks/useAuthentication";
import menus from "./menu";

export interface ComponentProps {
  broken: boolean;
}

const Navbar: FC<ComponentProps> = ({ broken }: ComponentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useAuthentication();

  const moduleKey = useMemo(() => {
    return history.location.pathname.split("/").filter((e) => e)?.[0];
  }, [history.location.pathname]);

  const [selectedMenuKeys, setSelectedMenuKeys] = useState<Array<string>>([]);
  const [openKeys, setOpenKeys] = useState<Array<string>>([moduleKey]);

  const fullname = useMemo(() => {
    if (!auth?.user?.firstname && !auth?.user?.lastname) {
      return auth?.user?.email;
    }
    return `${auth?.user?.firstname} ${auth?.user?.lastname}`;
  }, [auth]);

  useEffect(() => {
    const paths = history.location.pathname.split("/").filter((e) => e);
    setSelectedMenuKeys([paths.join("@")]);
    setOpenKeys([paths?.[0]]);
  }, [history.location]);

  const onLogout = (error: Error | null) => {
    if (error) {
      alert({
        type: "error",
        content: "Failed to logout",
      });
      return;
    }
    history.push("/login");
  };

  const handleClickMenu: MenuClickEventHandler = (info) => {
    switch (info.key) {
      case "logout": {
        Modal.confirm({
          title: "Logout",
          icon: <LogoutOutlined />,
          content: "Are you sure you want to log out?",
          onOk() {
            dispatch(actions.logout(onLogout));
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <div className="logo">
        {broken ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "inherit",
              paddingLeft: 24,
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/profile");
            }}
            aria-hidden
          >
            <Typography.Text strong ellipsis>
              {fullname}
            </Typography.Text>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Menu
        menus={menus}
        selectedKeys={selectedMenuKeys}
        openKeys={openKeys}
        onSelect={(info) => {
          setSelectedMenuKeys(info.selectedKeys);
        }}
        onOpenChange={(info) => {
          setOpenKeys(info as Array<string>);
        }}
        onClick={handleClickMenu}
      />
    </>
  );
};

export default Navbar;
