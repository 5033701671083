import axios from "../../libs/axios";
import type {
  FindOneRichMenuResponse,
  CreateRichMenuRequest,
  CreateRichMenuResponse,
} from "../../interfaces/richmenuAPI.interface";

export const findOneRichMenus = (
  id: number | string
): Promise<FindOneRichMenuResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/rich-menus/${id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const createRichMenu = (
  data: CreateRichMenuRequest | FormData
): Promise<CreateRichMenuResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/rich-menus", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const findDefaultRichMenu =
  (): Promise<FindOneRichMenuResponse | null> =>
    new Promise((resolve, reject) => {
      axios
        .get("/rich-menus", {
          params: {
            event: "default",
            status: "created",
          },
        })
        .then((result) => {
          if (result.data.length > 0) {
            resolve(result.data[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

export const findAfterRegisterRichMenu =
  (): Promise<FindOneRichMenuResponse | null> =>
    new Promise((resolve, reject) => {
      axios
        .get("/rich-menus", {
          params: {
            event: "after_register",
            status: "created",
          },
        })
        .then((result) => {
          if (result.data.length > 0) {
            resolve(result.data[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
