import { FC } from "react";

export interface ComponentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Component: FC<ComponentProps> = ({ children, style }: ComponentProps) => {
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 24, ...style }}>
      {children}
    </div>
  );
};

export default Component;
