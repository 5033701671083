import { Button, Col, Form, Row, Spin } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import WithAuthenticated from "../../components/WithAuthenticated";
import ContentContainer from "../../components/ContentContainer";
import Breadcrumb from "../../components/Breadcrumb";
import TextEditor from "../../components/TextEditor";
import { PDPA } from "../../interfaces/pdpa.interface";
import { getPDPA, updatePDPA } from "../../api/pdpa";
import alert from "../../components/AlertMessage";

export interface PDPAForm {
  detail: string;
}

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PDPA>();
  const [form] = Form.useForm<PDPAForm>();

  useEffect(() => {
    setLoading(true);
    getPDPA()
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch PDPA.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      detail: data?.detail,
    });
  }, [data, form]);

  const handleFormFinish = (values: PDPAForm) => {
    setLoading(true);
    updatePDPA(values)
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to update PDPA.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb
        title="PDPA"
        items={[{ title: "PDPA", location: "" }]}
        capitalizeTitle={false}
      />
      <ContentContainer>
        <Row style={{ marginBottom: 20 }}>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            span={24}
            sm={{ span: 10, offset: 14 }}
            md={{ span: 12, offset: 12 }}
          >
            <Button
              icon={<SaveOutlined />}
              type="primary"
              ghost
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={handleFormFinish}>
            <Form.Item
              name="detail"
              rules={[{ required: true, message: "Input is required!" }]}
            >
              <TextEditor />
            </Form.Item>
          </Form>
        </Spin>
      </ContentContainer>
    </>
  );
};

export default WithAuthenticated(Page);
