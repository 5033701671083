import axios from "../../libs/axios";
import type {
  GetProfileUser,
  UpdateProfileRequest,
} from "../../interfaces/profileAPI.interface";

export const getProfile = (): Promise<GetProfileUser> =>
  new Promise((resolve, reject) => {
    axios
      .get("/admin/users/me")
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateProfile = (
  form: UpdateProfileRequest
): Promise<GetProfileUser> =>
  new Promise((resolve, reject) => {
    axios
      .put("/admin/users/me", form)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateChangePassword = (
  password: string
): Promise<GetProfileUser> =>
  new Promise((resolve, reject) =>
    axios
      .put("/admin/users/me", { password })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
