export default (value: unknown): boolean => {
  try {
    if (typeof value === "string") {
      const obj = JSON.parse(value);
      if (typeof obj === "object" && obj) {
        return true;
      }
    } else if (typeof value === "object") {
      if (value) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
