import { Redirect } from "react-router-dom";
import type { WrappedComponentType } from "../../interfaces/component.interface";
import * as accessTokenManager from "../../libs/accessTokenManager";

function Component<T>(
  WrappedComponent: WrappedComponentType<T>
): WrappedComponentType<T> {
  return (props) => {
    const token = accessTokenManager.get();
    if (token) return <WrappedComponent {...props} />;
    return <Redirect to="/login" />;
  };
}

export default Component;
