import {
  Table,
  Input,
  Row,
  Col,
  Button,
  Spin,
  Card,
  Form,
  DatePicker,
  DatePickerProps,
  Select,
  Tooltip,
  Typography,
  Badge,
  Dropdown,
  Menu,
  Modal,
  Tag,
  Image,
  Space,
} from "antd";
import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Switch, useHistory } from "react-router-dom";
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SetStateAction,
} from "react";
import type { ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import WithAuthenticated from "../../../components/WithAuthenticated";
import Breadcrumb from "../../../components/Breadcrumb";
import ContentContainer from "../../../components/ContentContainer";
import alert from "../../../components/AlertMessage";
import { Broadcast } from "../../../interfaces/broadcast.interface";
import {
  countBroadcast,
  deleteBroadcast,
  getBroadcast,
  getBroadcastById,
  updateBroadcast,
} from "../../../api/broadcast";
import { formatFromISO, formatFromThai } from "../../../libs/moment";
import ResetPassword from "../../Login/ResetPassword";
import urlToFile from "../../../libs/urlToFile";

export type SearchForm = {
  broadcastStatus: string;
  startDate: string;
  endDate: string;
};

const BroadcastList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<Array<Broadcast>>([]);
  const [form] = Form.useForm<SearchForm>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dateStart, setDateStart] = useState<string>();
  const [dateEnd, setDateEnd] = useState<string>();
  const [FormStatus, setFormStatus] = useState<string>();
  const [dataView, setDataView] = useState<Broadcast>();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<number>();
  const [messageBroadcastsArray, setmessageBroadcasts] = useState([{}]);

  const { Title } = Typography;

  const handleDuplicate = useCallback(
    (data: Broadcast) => {
      history.push({
        pathname: "/broadcast/create",
        state: { data, messageBroadcastsArray },
      });
    },
    [history, messageBroadcastsArray]
  );

  const handleClickEdit = useCallback(
    (data: Broadcast) => {
      history.push({
        pathname: `/broadcast/edit/${data.id}`,
        state: { messageBroadcastsArray },
      });
    },
    [history, messageBroadcastsArray]
  );

  const onChangeDateStart: DatePickerProps["onChange"] = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // dateString: any
  ) => {
    const date = moment(data);
    const dateWithoutTime = date.startOf("day");
    setDateStart(dateWithoutTime.format());
  };
  const onChangeDateEnd: DatePickerProps["onChange"] = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    const date = moment(data);
    const dateWithoutTime = date.startOf("day");
    setDateEnd(
      dateWithoutTime.set({ hour: 23, minute: 59, second: 59 }).format()
    );
  };

  const onChangeStatus = (value: SetStateAction<string | undefined>) => {
    setFormStatus(value);
  };

  const handleClickDelete = useCallback((data: Broadcast, index: number) => {
    setLoading(true);
    deleteBroadcast(data.id)
      .then(() => {
        alert({
          type: "success",
          duration: 5,
          content: "Delete broadcast successfully",
        });
        setDataSource((prev) => {
          prev.splice(index, 1);
          return [...prev];
        });
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to delete Broadcast.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const showModelView = (value: any) => {
    setDataView(value);
    setOpen(true);
  };

  const widgetMenu = (value: any, index: number) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => showModelView(value)}>
          Detail
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleDuplicate(value)}>
          Duplicate
        </Menu.Item>
        <Menu.Item
          disabled={value.status === "Sent"}
          onClick={() => handleClickEdit(value)}
          key="3"
        >
          Edit
        </Menu.Item>
        <Menu.Item
          disabled={value.status === "Sent"}
          onClick={() => {
            Modal.confirm({
              title: "Delete Broadcast",
              content: `Are you sure you want to delete?`,
              icon: <DeleteOutlined />,
              onOk() {
                handleClickDelete(value, index);
              },
            });
          }}
          key="4"
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  // eslint-disable-next-line consistent-return
  const messageRender = (value: any) => {
    const rows = [];
    for (let index = 0; index < value.length; index += 1) {
      if (value[index].messageType === 1) {
        rows.push(
          <>
            <Title
              level={5}
              style={{ fontWeight: 600, marginTop: 16, display: "inline" }}
            >
              Message Type :
            </Title>
            <span style={{ fontWeight: 400 }}> Text Edit</span>
            <pre>{value[index].text}</pre>
          </>
        );
      }
      if (value[index].messageType === 2) {
        rows.push(
          <>
            <Title
              level={5}
              style={{ fontWeight: 600, marginTop: 16, display: "inline" }}
            >
              Message Type :
            </Title>
            <span style={{ fontWeight: 400 }}> Image</span>
            <div style={{ display: "block" }}>
              {value[index].image !== null ? (
                <Image
                  src={value[index].image?.url}
                  style={{ width: 100, display: "block" }}
                />
              ) : null}
            </div>
          </>
        );
      }
      if (value[index].messageType === 3) {
        rows.push(
          <>
            <Title
              level={5}
              style={{ fontWeight: 600, marginTop: 16, display: "inline" }}
            >
              Message Type :
            </Title>
            <span style={{ fontWeight: 400 }}> JSON</span>
            <p>{JSON.stringify(value[index].json)}</p>
          </>
        );
      }
    }
    return <div>{rows}</div>;
  };

  const defaultTextRender = (value: string) => {
    return (
      <Tooltip title={value}>
        <Typography.Text>{value}</Typography.Text>
      </Tooltip>
    );
  };

  const columns: ColumnsType<Broadcast> = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      align: "center",
      render: (text: string, record, index) => (
        <Typography.Text>
          {(currentPage - 1) * pageSize + (index + 1)}
        </Typography.Text>
      ),
    },
    {
      title: "Broadcast Name",
      dataIndex: "campaignName",
      key: "campaignName",
      render: defaultTextRender,
      sorter: (a, b) => a.campaignName.localeCompare(b.campaignName),
      width: "380px",
    },
    {
      title: "Recipients",
      dataIndex: "numberRecipients",
      key: "numberRecipients",
      render: defaultTextRender,
      sorter: {
        compare: (a, b) => a.numberRecipients - b.numberRecipients,
      },
    },
    {
      title: "Schedule Date",
      dataIndex: "dateTimeSpecify",
      key: "dateTimeSpecify",
      sorter: (a, b) =>
        moment(a.dateTimeSpecify).unix() - moment(b.dateTimeSpecify).unix(),
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromThai(value));
        }
        return null;
      },
    },
    {
      title: "Create Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        moment(a.dateTimeSpecify).unix() - moment(b.dateTimeSpecify).unix(),
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromThai(value));
        }
        return null;
      },
    },
    {
      title: "Publish Date",
      dataIndex: "dateTimeSpecify",
      key: "dateTimeSpecify",
      sorter: (a, b) =>
        moment(a.dateTimeSpecify).unix() - moment(b.dateTimeSpecify).unix(),
      render(value: string) {
        if (value) {
          return defaultTextRender(formatFromThai(value));
        }
        return null;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: string) => {
        if (data === "Sent") {
          return (
            <Badge
              className="status-sent"
              status="success"
              text="Sent"
              style={{ color: "#059669", fontSize: 16, fontWeight: 400 }}
            />
          );
        }
        if (data === "Draft") {
          return (
            <Badge
              className="status-draft"
              status="default"
              text="Draft"
              style={{ color: "#3C688C", fontSize: 16, fontWeight: 400 }}
            />
          );
        }
        if (data === "Schedule") {
          return (
            <Badge
              className="status-schedule"
              status="warning"
              text="Schedule"
              style={{ color: "#D97706", fontSize: 16, fontWeight: 400 }}
            />
          );
        }
        if (data === "Failed") {
          return (
            <Badge
              className="status-failed"
              status="error"
              text="Failed"
              style={{ color: "#E11D48", fontSize: 16, fontWeight: 400 }}
            />
          );
        }
        return null;
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record: Broadcast, index) => (
        <>
          <Dropdown overlay={widgetMenu(record, index)} trigger={["click"]}>
            <MoreOutlined
              className="action-active"
              onClick={() => setId(record.id)}
            />
          </Dropdown>
        </>
      ),
    },
  ];

  // const columns = useMemo<ColumnsType<Broadcast>>(() => {
  //   return generateColumns({
  //     onClickDetail: handleClickDetail,
  //     onClickEdit: handleClickEdit,
  //     onClickDelete: handleClickDelete,
  //     onUpdateIsActive: handleUpdateIsActive,
  //   });
  // }, [
  //   handleClickDelete,
  //   handleClickDetail,
  //   handleClickEdit,
  //   handleUpdateIsActive,
  // ]);

  const countSumValue = useMemo(() => {
    // setLoading(true);
    if (!id) {
      Promise.all([
        getBroadcast({
          _limit: -1,
          _sort: `created_at:desc`,
        }),
        countBroadcast(),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          // setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (id) {
      getBroadcastById(id).then(async (result) => {
        const messageBroadcasts = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < result.messageBroadcasts.length; index++) {
          let file;
          if (result.messageBroadcasts[index].image) {
            // eslint-disable-next-line no-await-in-loop
            file = await urlToFile(
              result.messageBroadcasts[index].image.url,
              result.messageBroadcasts[index].image.name,
              result.messageBroadcasts[index].image.mime
            );
          }
          if (result.messageBroadcasts[index].messageType === 1) {
            messageBroadcasts.push({
              no: index,
              messageType: result.messageBroadcasts[index].messageType,
              text: result.messageBroadcasts[index].text,
            });
          } else if (result.messageBroadcasts[index].messageType === 2) {
            messageBroadcasts.push({
              no: index,
              messageType: result.messageBroadcasts[index].messageType,
              image: file,
            });
          } else {
            messageBroadcasts.push({
              no: index,
              messageType: result.messageBroadcasts[index].messageType,
              json: JSON.stringify(result.messageBroadcasts[index].json),
            });
          }
        }
        setmessageBroadcasts(messageBroadcasts);
      });
    }
  }, [id]);

  const onSearch = (searchText: string) => {
    setLoading(true);
    Promise.all([
      getBroadcast({
        _limit: -1,
        // _start: (currentPage - 1) * pageSize,
        _q: searchText,
        _sort: `created_at:desc`,
      }),
      countBroadcast({
        _q: searchText,
      }),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
        setCurrentPage(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearchbyform = () => {
    setLoading(true);
    // let query = "";
    const today = formatFromISO(Date());
    if (
      (dateStart === undefined && dateEnd === undefined) ||
      (dateStart === "Invalid date" && dateEnd === "Invalid date")
    ) {
      Promise.all([
        getBroadcast({
          _limit: -1,
          // _start: (currentPage - 1) * pageSize,
          _sort: `created_at:desc`,
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
        }),
        countBroadcast({
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
        }),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (
      (dateStart === undefined || dateStart === "Invalid date") &&
      (dateEnd !== undefined || dateEnd !== "Invalid date")
    ) {
      Promise.all([
        getBroadcast({
          _limit: -1,
          // _start: (currentPage - 1) * pageSize,
          _sort: `created_at:desc`,
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_lte:
            dateEnd === undefined || dateEnd === "Invalid date"
              ? today
              : dateEnd,
        }),
        countBroadcast({
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_lte:
            dateEnd === undefined || dateEnd === "Invalid date"
              ? today
              : dateEnd,
        }),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (
      (dateStart !== undefined || dateStart !== "Invalid date") &&
      (dateEnd === undefined || dateEnd === "Invalid date")
    ) {
      Promise.all([
        getBroadcast({
          _limit: -1,
          // _start: (currentPage - 1) * pageSize,
          _sort: `created_at:desc`,
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_gte:
            dateStart === undefined || dateStart === "Invalid date"
              ? today
              : dateStart,
        }),
        countBroadcast({
          _q: "",
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_gte:
            dateStart === undefined || dateStart === "Invalid date"
              ? today
              : dateStart,
        }),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Promise.all([
        getBroadcast({
          _limit: -1,
          // _start: (currentPage - 1) * pageSize,
          _sort: `created_at:desc`,
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_gte:
            dateStart === undefined || dateStart === "Invalid date"
              ? today
              : dateStart,
          dateTimeSpecify_lte:
            dateEnd === undefined || dateEnd === "Invalid date"
              ? today
              : dateEnd,
        }),
        countBroadcast({
          status_contains: FormStatus === "All" ? "" : FormStatus,
          dateTimeSpecify_gte:
            dateStart === undefined && dateStart === "Invalid date"
              ? today
              : dateStart,
          dateTimeSpecify_lte:
            dateEnd === undefined && dateEnd === "Invalid date"
              ? today
              : dateEnd,
        }),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (dateStart === undefined && dateEnd === undefined) {
      Promise.all([
        getBroadcast({
          _limit: -1,
          // _start: (currentPage - 1) * pageSize,
          _sort: `created_at:desc`,
          status_contains: FormStatus === "All" ? "" : FormStatus,
        }),
        countBroadcast(),
      ])
        .then(async ([result, count]) => {
          setDataSource(result);
          setPageCount(count);
          setCurrentPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormStatus("All");
    setDateStart(undefined);
    setDateEnd(undefined);
    setLoading(true);
    Promise.all([
      getBroadcast({
        _limit: -1,
        _sort: `created_at:desc`,
      }),
      countBroadcast(),
    ])
      .then(async ([result, count]) => {
        setDataSource(result);
        setPageCount(count);
        setCurrentPage(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        centered
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={null}
      >
        <Title
          level={4}
          style={{ fontWeight: 400, marginBottom: 26, fontSize: 24 }}
        >
          Broadcast Detail
        </Title>
        <Title level={5} style={{ fontWeight: 600 }}>
          Broadcast Name :
        </Title>
        <p>{dataView?.campaignName}</p>
        <Title level={5} style={{ fontWeight: 600 }}>
          Broadcast Details :
        </Title>
        <p>{dataView?.campaignDetails}</p>
        <Title level={5} style={{ fontWeight: 600 }}>
          Recipients :
        </Title>
        <p style={{ marginBottom: 8 }}>Targeting</p>
        {dataView?.RecipientsType === 1 ? (
          <Tooltip title="All">
            <Tag className="tag-view">All</Tag>
          </Tooltip>
        ) : null}
        {dataView?.RecipientsType === 2 && dataView?.userType.length === 1 ? (
          <Tooltip title={dataView?.userType[0].type}>
            <Tag className="tag-view">{dataView?.userType[0].type}</Tag>
          </Tooltip>
        ) : null}
        {dataView?.RecipientsType === 2 && dataView?.userType.length === 2 ? (
          <>
            <Tooltip title="บุคคลธรรมดา">
              <Tag className="tag-view">บุคคลธรรมดา</Tag>
            </Tooltip>
            <Tooltip title="นิติบุคคล">
              <Tag className="tag-view">นิติบุคคล</Tag>
            </Tooltip>
          </>
        ) : null}
        {dataView?.RecipientsType === 2 && dataView?.targetGroup?.length !== 0
          ? dataView?.targetGroup?.map((v, idx) => {
              return (
                <Tooltip key={idx} title={v}>
                  <Tag className="tag-view">{v}</Tag>
                </Tooltip>
              );
            })
          : null}
        <Title level={5} style={{ fontWeight: 600 }}>
          Broadcast Channel :
        </Title>
        <p>Patconnex</p>
        <Title level={5} style={{ fontWeight: 600 }}>
          Status Broadcast :
        </Title>
        <p>{dataView?.status}</p>
        <Title level={5} style={{ fontWeight: 600 }}>
          Schedule :
        </Title>
        <p>{moment(dataView?.dateTimeSpecify).format("DD/MM/YYYY HH:mm")}</p>
        {dataView?.messageBroadcasts !== undefined
          ? messageRender(dataView?.messageBroadcasts)
          : null}
      </Modal>
      <Breadcrumb
        title="Broadcast"
        items={[{ title: "Broadcast", location: "" }]}
      />
      <Spin spinning={loading}>
        <ContentContainer>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
              span={24}
              sm={14}
              md={12}
            >
              <Input.Search
                placeholder="Search Broadcast Name"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={10}
              md={12}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  history.push(`/broadcast/create`);
                }}
              >
                Create Broadcast
              </Button>
            </Col>
          </Row>
          <Card
            style={{
              width: "100%",
              marginBottom: 24,
              boxShadow: "0px 4px 8px 0px #B2B2B226",
            }}
          >
            <Form
              form={form}
              labelCol={{ span: 5 }}
              // onFinish={onFinish}
              autoComplete="off"
              className="formSearch"
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Broadcast Status"
                    name="broadcastStatus"
                    style={{ display: "block", paddingRight: 16 }}
                  >
                    <Select
                      defaultValue="All"
                      style={{
                        width: "100%",
                      }}
                      onChange={onChangeStatus}
                      options={[
                        {
                          value: "All",
                          label: "All",
                        },
                        {
                          value: "Sent",
                          label: "Sent",
                        },
                        {
                          value: "Schedule",
                          label: "Schedule",
                        },
                        {
                          value: "Draft",
                          label: "Draft",
                        },
                        {
                          value: "Failed",
                          label: "Failed",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    style={{
                      display: "block",
                      paddingRight: 8,
                      paddingLeft: 8,
                    }}
                  >
                    <DatePicker
                      placeholder="Publish Date"
                      onChange={onChangeDateStart}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    style={{ display: "block", paddingLeft: 16 }}
                  >
                    <DatePicker
                      placeholder="Publish Date"
                      onChange={onChangeDateEnd}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: 16 }}>
                <Col>
                  <Button
                    htmlType="button"
                    type="primary"
                    ghost
                    onClick={resetForm}
                    style={{ marginRight: 8 }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={onSearchbyform}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Table
            locale={{ emptyText: "-- No Broadcast Data --" }}
            className="table-bc"
            rowKey="id"
            columns={columns}
            // loading={loading}
            dataSource={dataSource}
            pagination={{
              // total: pageCount,
              current: currentPage,
              pageSize,
              onChange: (page, pagesize) => {
                setCurrentPage(page);
                setPageSize(pagesize || 0);
              },
            }}
            style={{ overflowX: "scroll" }}
          />
        </ContentContainer>
      </Spin>
    </>
  );
};

export default WithAuthenticated(BroadcastList);
