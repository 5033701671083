import { FC, useMemo } from "react";
import { Redirect } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import menus from "../Layout/menu";

const HomePage: FC = () => {
  const auth = useAuthentication();

  const defaultLink = useMemo((): string => {
    const firstMenu = menus[0];

    if (firstMenu) {
      if (firstMenu.childrens && firstMenu.childrens.length > 0) {
        return firstMenu.childrens[0].link.to;
      }
      if (firstMenu.link) {
        return firstMenu.link.to;
      }
    }

    return "/profile";
  }, []);

  if (!auth) {
    return <Redirect to="/login" />;
  }

  return <Redirect to={defaultLink} />;
};

export default HomePage;
