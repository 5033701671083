import Icon, {
  ContactsOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PictureOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactComponent as logo } from "../../assets/broadcast-svgrepo-com.svg";
import { MenuSetting } from "../Menu";

const menus: MenuSetting = [
  {
    key: "banner",
    icon: <PictureOutlined />,
    title: "Banner Setting",
    link: {
      to: "/banner",
      label: "Banner",
    },
  },
  {
    key: "faq",
    icon: <QuestionCircleOutlined />,
    title: "FAQ Setting",
    link: {
      to: "/faq",
      label: "FAQ",
    },
  },
  {
    key: "about-us",
    icon: <InfoCircleOutlined />,
    title: "About Us",
    link: {
      to: "/about-us",
      label: "About Us",
    },
  },
  {
    key: "contact-us",
    icon: <ContactsOutlined />,
    title: "Contact Us Setting",
    link: {
      to: "/contact-us",
      label: "Contact Us",
    },
  },
  {
    key: "privacy",
    icon: <FileTextOutlined />,
    title: "Privacy",
    link: {
      to: "/privacy",
      label: "Privacy",
    },
  },
  {
    key: "pdpa",
    icon: <FileTextOutlined />,
    title: "PDPA",
    link: {
      to: "/pdpa",
      label: "PDPA",
    },
  },
  {
    key: "footer-setting",
    icon: <FileTextOutlined />,
    title: "Footer Setting",
    link: {
      to: "/footer-setting",
      label: "Footer Setting",
    },
  },
  {
    key: "announcement",
    icon: <NotificationOutlined />,
    title: "Announcement",
    childrens: [
      {
        key: "list",
        link: {
          to: "/announcement/list",
          label: "List",
        },
      },
      {
        key: "create",
        link: {
          to: "/announcement/create",
          label: "Create",
        },
      },
    ],
  },
  // {
  //   key: "news-category",
  //   icon: <ReadOutlined />,
  //   title: "News Category",
  //   childrens: [
  //     {
  //       key: "list",
  //       link: {
  //         to: "/news-category/list",
  //         label: "List",
  //       },
  //     },
  //     {
  //       key: "create",
  //       link: {
  //         to: "/news-category/create",
  //         label: "Create",
  //       },
  //     },
  //   ],
  // },
  {
    key: "news",
    icon: <ReadOutlined />,
    title: "News",
    childrens: [
      {
        key: "list",
        link: {
          to: "/news/list",
          label: "List",
        },
      },
      {
        key: "create",
        link: {
          to: "/news/create",
          label: "Create",
        },
      },
    ],
  },
  {
    key: "rich-menu",
    icon: <PictureOutlined />,
    title: "Rich Menu Setting",
    link: {
      to: "/rich-menu",
      label: "Rich Menu",
    },
  },
  {
    key: "user",
    icon: <TeamOutlined />,
    title: "User",
    link: {
      to: "/user",
      label: "User",
    },
  },
  {
    key: "userLog",
    icon: <SolutionOutlined />,
    title: "UserLog",
    link: {
      to: "/LogUser",
      label: "Log User",
    },
  },
  {
    key: "admin",
    icon: <UserOutlined />,
    title: "Administrator",
    childrens: [
      {
        key: "list",
        link: {
          to: "/admin/list",
          label: "List",
        },
      },
      {
        key: "create",
        link: {
          to: "/admin/create",
          label: "Create",
        },
      },
    ],
  },
  {
    key: "broadcast",
    // eslint-disable-next-line jsx-a11y/alt-text
    icon: <Icon component={logo} className="hoverIcon" />,
    title: "broadcast",
    link: {
      to: "/broadcast",
      label: "Broadcast",
    },
  },
  {
    key: "logout",
    icon: <LogoutOutlined />,
    title: "Logout",
  },
];

export default menus;
