import { useEffect, useState, useMemo, useCallback } from "react";
import { Form, Input, Button, Spin, Modal, Row, Col } from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import alert from "../../../components/AlertMessage";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  createNewsCategory,
  deleteNewsCategory,
  getNewsCategoryById,
  updateNewsCategory,
} from "../../../api/newsCategory";

export type PageType = "create" | "edit" | "detail";

export interface NewsCategoryCreationForm {
  title: string;
}

const Page = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<Record<"id", string>>();
  const [form] = Form.useForm<NewsCategoryCreationForm>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getNewsCategoryById(id)
        .then((result) => {
          form.setFieldsValue({
            title: result.title,
          });
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form, id]);

  const pageType: PageType = useMemo(() => {
    if (location.pathname === `/news-category/detail/${id}`) return "detail";
    if (location.pathname === `/news-category/edit/${id}`) return "edit";
    return "create";
  }, [location, id]);

  const isPage = useCallback(
    (type: PageType) => {
      return pageType === type;
    },
    [pageType]
  );

  const shouldRenderSubmitButton = useMemo(() => {
    return pageType === "create" || pageType === "edit";
  }, [pageType]);

  const shouldRenderEditButton = useMemo(() => {
    return pageType === "detail";
  }, [pageType]);

  const shouldRenderDeleteButton = useMemo(() => {
    return pageType === "detail" || pageType === "edit";
  }, [pageType]);

  const onFinish = (values: NewsCategoryCreationForm) => {
    if (pageType === "edit") {
      setLoading(true);
      updateNewsCategory(id, values)
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Update News Category successfully.",
          });
          setLoading(false);
          history.push(`/news-category/${id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to update News Category.",
          });
          setLoading(false);
        });
    } else {
      setLoading(true);
      createNewsCategory(values)
        .then((result) => {
          alert({
            type: "success",
            duration: 5,
            content: "Create News Category successfully.",
          });
          setLoading(false);
          history.push(`/news-category/detail/${result.id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to create News Category.",
          });
          setLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: "Delete News Category",
      content: "Are you sure you want to delete?",
      icon: <DeleteOutlined />,
      onOk() {
        setLoading(true);
        deleteNewsCategory(id).then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Delete News Category successfully",
          });
          setLoading(false);
          history.push("/news-category/list");
        });
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="News Category"
        items={[
          { title: "News Category", location: "/news-category/list" },
          { title: capitalize(pageType), location: "" },
        ]}
      />
      <ContentContainer>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 8,
            },
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Title is required." }]}
          >
            <Input bordered={!isPage("detail")} readOnly={isPage("detail")} />
          </Form.Item>
          <Row>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 8,
              }}
            >
              {shouldRenderSubmitButton && (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
              {shouldRenderEditButton && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/news-category/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                style={{ marginLeft: 5 }}
                htmlType="button"
                onClick={() => {
                  history.push("/news-category/list");
                }}
              >
                Cancel
              </Button>
              {shouldRenderDeleteButton && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="default"
                  onClick={handleClickDelete}
                  danger
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
