import { grey } from "@ant-design/colors";
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Form,
  FormInstance,
  Input,
  Tooltip,
  Typography,
} from "antd";
import { get } from "lodash";
import React, { FC, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ContactChannelFormItems from "../../components/ContactChannelFormItems";
import Draggable from "../../components/Draggable";
import { ContactChannel } from "../../interfaces/contactUs.interface";

export interface Contact {
  channel?: ContactChannel;
  label?: string;
  description?: string;
}

export interface FormValues {
  label?: string;
  contacts?: Contact[];
}

export interface FooterContactSubFormProps {
  id: string;
  name: number;
  form: FormInstance;
  parentName: (number | string)[];
  type: string;
  wrapperStyle?: React.CSSProperties;
  move: (dragIndex: number, hoverIndex: number) => void;
  remove: (index: number) => void;
}

const FooterContactFormItems: FC<FooterContactSubFormProps> = ({
  id,
  name,
  form,
  parentName,
  type,
  wrapperStyle = {},
  move,
  remove,
}: FooterContactSubFormProps) => {
  const [formValues, setFormValues] = useState<FormValues>();
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>();

  const isValid = useMemo<boolean>(() => {
    const result = form.getFieldValue([...parentName, name]) || null;

    if (result) {
      const { contacts } = result as FormValues;

      if (contacts && contacts.length > 0) {
        const valid = contacts.reduce((acc, cur) => {
          if (cur.channel && cur.description) {
            return acc && true;
          }
          return acc && false;
        }, true);
        return valid;
      }
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formValues, name, parentName]);

  const collapseTitle = useMemo<React.ReactNode>(() => {
    const fieldsValues = form?.getFieldsValue();
    const result = get(fieldsValues, [...parentName, name], null);

    if (result) {
      const { label } = result as FormValues;

      if (label) {
        return (
          <Tooltip title={label}>
            <Typography.Text style={{ maxWidth: "80%" }} ellipsis>
              {label}
            </Typography.Text>
          </Tooltip>
        );
      }
    }

    return <QuestionOutlined />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formValues, name, parentName]);

  return (
    <Draggable
      style={{
        marginTop: "15px",
        cursor: "move",
        ...wrapperStyle,
      }}
      type={type}
      id={id}
      index={name}
      move={move}
    >
      <Collapse
        defaultActiveKey={!isValid ? "1" : undefined}
        activeKey={!isValid ? "1" : activeKey}
        onChange={(key) => {
          if (Array.isArray(key)) {
            if (key.length > 0) {
              setActiveKey(key);
              return;
            }
          } else if (key) {
            setActiveKey(key);
            return;
          }
          setActiveKey(!isValid ? "1" : undefined);
        }}
      >
        <Collapse.Panel
          key="1"
          header={collapseTitle}
          extra={
            <DeleteOutlined
              style={{
                fontSize: 16,
                color: grey.primary,
              }}
              onClick={() => remove(name)}
            />
          }
        >
          <Form.Item name={[name, "label"]} label="Label">
            <Input
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  label: e.target.value,
                }));
              }}
            />
          </Form.Item>
          <Form.List name={[name, "contacts"]}>
            {(fields, formListOperation) => (
              <div>
                <DndProvider backend={HTML5Backend}>
                  {fields.map((field) => (
                    <ContactChannelFormItems
                      key={field.key}
                      type={[name, "contacts"].join(".")}
                      id={field.key.toString()}
                      name={field.name}
                      form={form}
                      parentName={[...parentName, name, "contacts"]}
                      move={formListOperation.move}
                      remove={formListOperation.remove}
                      onChange={(value) => {
                        setFormValues((prev) => {
                          const contacts = prev?.contacts || [];
                          contacts[field.name] = {
                            channel: value.channel,
                            label: value.label,
                            description: value.description,
                          };
                          return {
                            ...prev,
                            contacts,
                          };
                        });
                      }}
                    />
                  ))}
                </DndProvider>
                <Form.Item label=" " colon={false}>
                  <Button
                    type="dashed"
                    onClick={() => formListOperation.add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Contact
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Collapse.Panel>
      </Collapse>
    </Draggable>
  );
};

export default FooterContactFormItems;
