/* eslint-disable jsx-a11y/anchor-is-valid */
import { Layout, Form, Input, Button, Row, Col, Image } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import WithNonAuthenticated from "../../components/WithNonAuthenticated";
import type { AdminInvitationForm } from "../../interfaces/admin.interface";
import { getRegistrationInfo, registerAdmin } from "../../api/admin";
import logo from "../../assets/base-logo.svg";
import "./index.css";
import alert from "../../components/AlertMessage";

const Container = (): JSX.Element => {
  const [form] = Form.useForm();
  const registrationToken = new URLSearchParams(useLocation().search).get(
    "registrationToken"
  );
  const history = useHistory();

  useEffect(() => {
    if (registrationToken !== null) {
      getRegistrationInfo(registrationToken)
        .then((result) => {
          const admin = result.data;
          form.setFieldsValue({
            firstname: admin.firstname,
            lastname: admin.lastname,
            email: admin.email,
          });
        })
        .catch(() => {
          history.push("/login");
          alert({
            type: "error",
            duration: 4,
            content: "Invalid registration token",
          });
        });
    } else {
      history.push("/login");
      alert({
        type: "error",
        duration: 4,
        content: "Invalid registrationToken",
      });
    }
  }, [registrationToken, form, history]);

  const onFinish = (values: AdminInvitationForm) => {
    registerAdmin({
      registrationToken,
      userInfo: {
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
      },
    }).then(() => {
      history.push("/login");
    });
  };
  return (
    <>
      <Layout.Content className="InvitationForm">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image preview={false} width={250} src={logo} />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[16, 8]}>
            <Col span={24} md={12}>
              <Form.Item
                label="First name"
                name="firstname"
                rules={[{ required: true, message: "First name is required." }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Last name"
                name="lastname"
                rules={[{ required: true, message: "Last name is required." }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Email is required." }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Passowrd is required.",
              },
              () => ({
                validator(_, value) {
                  const lowercase = new RegExp("(?=.*[a-z])[a-z]");
                  const uppercase = new RegExp("(?=.*[A-Z])[A-Z]");
                  const number = new RegExp("(?=.*[0-9])[0-9]");
                  if (!value || value.length < 8) {
                    return Promise.reject(new Error("The value is too short."));
                  }
                  if (!value || !value || !lowercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one lowercase character."
                      )
                    );
                  }
                  if (!value || !uppercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one uppercase character."
                      )
                    );
                  }
                  if (!value || !number.test(value)) {
                    return Promise.reject(
                      new Error("Password must contain at least one number.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Confirm password is required.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match."
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Let&apos;s Start
            </Button>
          </Form.Item>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          Already have an account?&nbsp;
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          >
            Login
          </a>
        </div>
      </Layout.Content>
    </>
  );
};
export default WithNonAuthenticated(Container);
