import { FC, useMemo } from "react";
import { Image, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import urlJoin from "url-join";
import "./style.css";
import alert from "../AlertMessage";
import isURL from "../../libs/isURL";
import setting from "../../config";
import getImageDimensions from "../../libs/getImageDimensions";
import isLteMB from "../../libs/isLteMB";

type BeforeUploadValueType = void | boolean | string | Blob | File;

export interface ComponentProps {
  value?: string;
  onChange?: (value: string) => void;
}

const Component: FC<ComponentProps> = ({ value, onChange }: ComponentProps) => {
  const imageSrc = useMemo(() => {
    if (isURL(value || "")) return value;
    return urlJoin(setting.BASE_API_URL || "", value || "");
  }, [value]);

  const handleUploadChange = (info: UploadChangeParam<UploadFile<unknown>>) => {
    let file: File | null;
    if (info.file instanceof File) {
      file = info.file;
    } else if (info.file.originFileObj instanceof File) {
      file = info.file.originFileObj;
    } else {
      file = null;
    }

    if (file) {
      onChange?.(URL.createObjectURL(file));
    } else {
      alert({
        type: "error",
        content: "failed to read file",
      });
    }
  };

  const beforeUpload = async (file: File): Promise<BeforeUploadValueType> => {
    const { width, height } = await getImageDimensions(file);

    const isValidWidth = width >= 800 && width <= 2500;
    if (!isValidWidth) {
      alert({
        type: "error",
        content:
          "Image width must smaller than 800 and wider than 2500 pixels.",
      });
      return Upload.LIST_IGNORE;
    }

    const isValidHeight = height >= 250;
    if (!isValidHeight) {
      alert({
        type: "error",
        content: "Image must heigher than 250 pixels.",
      });
      return Upload.LIST_IGNORE;
    }

    const isValidRatio = width / height >= 1.45;
    if (!isValidRatio) {
      alert({
        type: "error",
        content: "Image aspect ratio must more than 1.45.",
      });
      return Upload.LIST_IGNORE;
    }

    const isValidMime = file.type === "image/jpeg" || file.type === "image/png";
    if (!isValidMime) {
      alert({
        type: "error",
        content: "You can only upload JEPG/PNG file.",
      });
      return Upload.LIST_IGNORE;
    }

    const isLte1M = isLteMB(1);
    const isValidSize = isLte1M(file.size);
    if (!isValidSize) {
      alert({
        type: "error",
        content: "Image must smaller than 1MB.",
      });
      return Upload.LIST_IGNORE;
    }

    if (
      isValidWidth &&
      isValidHeight &&
      isValidRatio &&
      isValidMime &&
      isValidSize
    ) {
      return true;
    }

    return Upload.LIST_IGNORE;
  };

  return (
    <>
      <Upload
        style={{ textAlign: "center" }}
        listType="picture-card"
        className="image-uploader"
        showUploadList={false}
        accept="image/jpeg,image/png"
        beforeUpload={beforeUpload}
        customRequest={() => {}}
        onChange={handleUploadChange}
      >
        {value ? (
          <Image
            src={imageSrc}
            alt="rich-menu"
            style={{ width: "100%" }}
            preview={false}
          />
        ) : (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
      <p style={{ fontSize: 12 }}>
        The image must be JPEG or PNG, with a width size (pixels) of 800 to
        2500, a height size (pixels) greater than 250, an aspect ratio
        (width/height) greater than 1.45, and a file size less than or equal to
        1 MB.
      </p>
    </>
  );
};

export default Component;
