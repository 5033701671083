import { grey } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";
import { Collapse, Form, FormInstance, Input, Select, Typography } from "antd";
import { capitalize, get } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ContactChannel } from "../../interfaces/contactUs.interface";
import ContactChannelLabel from "../ContactChannelLabel";
import Draggable from "../Draggable";

const channels: string[] = Object.values(ContactChannel);

export interface FormValues {
  channel?: ContactChannel;
  label?: string;
  description?: string;
}

export interface ContactChannelFormItemsProps {
  id: string;
  name: number;
  wrapperStyle?: React.CSSProperties;
  form: FormInstance;
  parentName: (number | string)[];
  type: string;
  move: (dragIndex: number, hoverIndex: number) => void;
  remove: (index: number) => void;
  onChange?: (value: FormValues) => void;
}

const ContactChannelFormItems: FC<ContactChannelFormItemsProps> = ({
  id,
  name,
  form,
  parentName,
  type,
  wrapperStyle = {},
  move,
  remove,
  onChange,
}: ContactChannelFormItemsProps) => {
  const [formValues, setFormValues] = useState<FormValues>();
  const [activeKey, setActiveKey] = useState<string | string[] | undefined>();

  const isValid = useMemo<boolean>(() => {
    const fieldsValues = form?.getFieldsValue();
    const result = get(fieldsValues, [...parentName, name], null);

    if (result) {
      const { channel, description } = result as FormValues;

      if (channel && description) {
        return true;
      }
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formValues, name, parentName]);

  const collapseTitle = useMemo<React.ReactNode>(() => {
    const fieldsValues = form?.getFieldsValue();
    const result = get(fieldsValues, [...parentName, name], null);

    if (result) {
      const { channel, description } = result as FormValues;

      return (
        <ContactChannelLabel channel={channel} description={description} />
      );
    }

    return <ContactChannelLabel />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formValues, name, parentName]);

  useEffect(() => {
    if (!isValid) {
      setActiveKey("1");
    }
  }, [isValid]);

  useEffect(() => {
    if (formValues) {
      onChange?.({
        channel: formValues?.channel,
        label: formValues?.label,
        description: formValues?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <Draggable
      style={{
        marginTop: "15px",
        cursor: "move",
        ...wrapperStyle,
      }}
      type={type}
      id={id}
      index={name}
      move={move}
    >
      <Collapse
        defaultActiveKey={!isValid ? "1" : undefined}
        activeKey={!isValid ? "1" : activeKey}
        onChange={(key) => {
          if (Array.isArray(key)) {
            if (key.length > 0) {
              setActiveKey(key);
              return;
            }
          } else if (key) {
            setActiveKey(key);
            return;
          }
          setActiveKey(!isValid ? "1" : undefined);
        }}
      >
        <Collapse.Panel
          key="1"
          header={
            <Typography.Text style={{ maxWidth: "80%" }} ellipsis>
              {collapseTitle}
            </Typography.Text>
          }
          extra={
            <DeleteOutlined
              style={{
                fontSize: 16,
                color: grey.primary,
              }}
              onClick={() => remove(name)}
            />
          }
        >
          <Form.Item
            name={[name, "channel"]}
            label="Channel"
            rules={[{ required: true }]}
          >
            <Select
              options={channels.map((channel) => ({
                label: capitalize(channel),
                value: channel,
              }))}
              onChange={(value) => {
                setFormValues((prev) => ({
                  ...prev,
                  channel: value as ContactChannel,
                }));
              }}
            />
          </Form.Item>
          <Form.Item name={[name, "label"]} label="Label">
            <Input
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  label: e.target.value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name={[name, "description"]}
            label="Description"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </Draggable>
  );
};

export default ContactChannelFormItems;
