import {
  CreateNewsCategoryData,
  NewsCategory,
  UpdateNewsCategoryData,
} from "../../interfaces/newsCategory.interface";
import { GeneralGetRequestOption } from "../../interfaces/request.interface";
import axios from "../../libs/axios";

export const getNewsCategories = async (
  option?: GeneralGetRequestOption
): Promise<NewsCategory[]> => {
  const response = await axios.get<NewsCategory[]>(
    "/press-release-categories",
    {
      params: option,
    }
  );
  return response.data;
};

export const getNewsCategoryById = async (
  id: number | string
): Promise<NewsCategory> => {
  const response = await axios.get<NewsCategory>(
    `/press-release-categories/${id}`
  );
  return response.data;
};

export const countNewsCategory = async (
  option?: GeneralGetRequestOption
): Promise<number> => {
  const response = await axios.get<number>("/press-release-categories/count", {
    params: option,
  });
  return response.data;
};

export const createNewsCategory = async (
  data: CreateNewsCategoryData
): Promise<NewsCategory> => {
  const response = await axios.post<NewsCategory>(
    "/press-release-categories",
    data
  );
  return response.data;
};

export const updateNewsCategory = async (
  id: number | string,
  data: Partial<UpdateNewsCategoryData>
): Promise<NewsCategory> => {
  const response = await axios.put<NewsCategory>(
    `/press-release-categories/${id}`,
    data
  );
  return response.data;
};

export const deleteNewsCategory = async (
  id: number | string
): Promise<NewsCategory> => {
  const response = await axios.delete<NewsCategory>(
    `/press-release-categories/${id}`
  );
  return response.data;
};
