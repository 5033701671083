import axios from "axios";

export default (url: string): Promise<Blob> =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
