import { Footer } from "../../interfaces/footer.interface";
import axios from "../../libs/axios";

export const getFooter = async (): Promise<Footer> => {
  const response = await axios.get<Footer>("/footer");
  return response.data;
};

export const updateFooter = async (data: Footer): Promise<Footer> => {
  const response = await axios.put<Footer>("/footer", data);
  return response.data;
};
