import { blue, red } from "@ant-design/colors";
import { Modal, Space, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { NewsCategory } from "../../../interfaces/newsCategory.interface";

export type GenerateColumnsOptions = {
  onClickDetail: (data: NewsCategory, index: number) => void;
  onClickEdit: (data: NewsCategory, index: number) => void;
  onClickDelete: (data: NewsCategory, index: number) => void;
};

const generateColumns = (
  options: GenerateColumnsOptions
): ColumnsType<NewsCategory> => {
  const columns: ColumnsType<NewsCategory> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text: string, record, index) => (
        <Typography.Text
          style={{ color: blue.primary, cursor: "pointer" }}
          onClick={() => {
            options.onClickDetail(record, index);
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: blue.primary }}
            onClick={() => {
              options.onClickEdit(record, index);
            }}
          />
          <DeleteOutlined
            style={{ color: red.primary }}
            onClick={() => {
              Modal.confirm({
                title: "Delete News Category",
                content: `Are you sure you want to delete?`,
                icon: <DeleteOutlined />,
                onOk() {
                  options.onClickDelete(record, index);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  return columns;
};

export default generateColumns;
