import { useState } from "react";
import { Button, Col, Divider, Form, Input, Row, Spin, Typography } from "antd";
import axios from "axios";
import has from "lodash/has";
import { updateChangePassword } from "../../../api/profile";
import { ChangePasswordForm } from "../../../interfaces/profile.interface";
import alert from "../../AlertMessage";

const Component = (): JSX.Element => {
  const [form] = Form.useForm<ChangePasswordForm>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmitChangePassword = (value: ChangePasswordForm) => {
    if (value.confirmpassword !== value.newpassword) {
      alert({ type: "error", content: "Passwords does not match" });
    } else {
      setLoading(true);
      updateChangePassword(value.newpassword)
        .then(() => {
          alert({
            type: "success",
            content: "Change password successfully",
          });
          form.resetFields();
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            if (has(error, "response.data.data.password")) {
              alert({
                type: "error",
                content: error.response?.data.data.password,
              });
              return;
            }
          }
          alert({ type: "error", content: "Cannot change password" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="ChangePassword">
      <Spin spinning={loading}>
        <Typography.Title level={5}>Password</Typography.Title>
        <Divider />
        <Form
          onFinish={handleSubmitChangePassword}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 8,
            },
          }}
        >
          <Form.Item
            name="newpassword"
            label="New Password"
            rules={[
              { required: true, message: "New password is required." },
              () => ({
                validator(_, value) {
                  const lowercase = new RegExp("(?=.*[a-z])[a-z]");
                  const uppercase = new RegExp("(?=.*[A-Z])[A-Z]");
                  const number = new RegExp("(?=.*[0-9])[0-9]");
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!value || value.length < 8) {
                    return Promise.reject(new Error("The value is too short."));
                  }
                  if (!value || !value || !lowercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one lowercase character."
                      )
                    );
                  }
                  if (!value || !uppercase.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one uppercase character."
                      )
                    );
                  }
                  if (!value || !number.test(value)) {
                    return Promise.reject(
                      new Error("Password must contain at least one number.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password placeholder="Please enter New password." />
          </Form.Item>
          <Form.Item
            name="confirmpassword"
            label="Confirm Password"
            rules={[
              { required: true, message: "Confirm password is required." },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newpassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match."
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Please enter Confirm password." />
          </Form.Item>
          <Row>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 8,
              }}
            >
              <Button
                style={{ marginRight: 5, width: "140px", height: "32px" }}
                htmlType="submit"
                type="primary"
              >
                Change Password
              </Button>
              <Button
                htmlType="button"
                onClick={() => form.resetFields()}
                style={{ marginLeft: 5 }}
                type="default"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default Component;
