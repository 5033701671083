import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Row,
  Col,
  Select,
  Switch,
} from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import alert from "../../../components/AlertMessage";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import {
  createNews,
  deleteNews,
  getNewsById,
  updateNews,
} from "../../../api/news";
import { NewsCategory } from "../../../interfaces/newsCategory.interface";
import { getNewsCategories } from "../../../api/newsCategory";
import TextEditor from "../../../components/TextEditor";
import SingleImageUploadInput from "../../../components/SingleImageUploadInput";
import urlToFile from "../../../libs/urlToFile";
import { upload } from "../../../api/upload";
import { StrapiImage } from "../../../interfaces/strapi.interface";
import { News } from "../../../interfaces/news.interface";

export type PageType = "create" | "edit" | "detail";

export interface NewsCreationForm {
  pressReleaseCategory: number;
  title: string;
  description: string;
  coverImage: File;
  tags: string[];
  isActive: boolean;
  isHighlight: boolean;
}

const Page = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<Record<"id", string>>();
  const [form] = Form.useForm<NewsCreationForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<NewsCategory[]>([]);
  const [news, setNews] = useState<News>();
  const [shouldReUpload, setShouldReUpload] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Promise.all([
        getNewsById(id),
        getNewsCategories({
          _limit: -1,
          _sort: "created_at:desc",
        }),
      ])
        .then(async ([result, categoryResult]) => {
          setCategories(categoryResult);
          setNews(result);
          const file = await urlToFile(
            result.coverImage.url,
            result.coverImage.name,
            result.coverImage.mime
          );
          form.setFieldsValue({
            pressReleaseCategory: result.pressReleaseCategory.id,
            title: result.title,
            description: result.description,
            isActive: result.isActive,
            isHighlight: result.isHighlight,
            tags: result.tags || [],
            coverImage: file,
          });
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      Promise.all([
        getNewsCategories({
          _limit: -1,
          _sort: "created_at:desc",
        }),
      ])
        .then(([categoryResult]) => {
          setCategories(categoryResult);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form, id]);

  const pageType: PageType = useMemo(() => {
    if (location.pathname === `/news/detail/${id}`) return "detail";
    if (location.pathname === `/news/edit/${id}`) return "edit";
    return "create";
  }, [location, id]);

  const isPage = useCallback(
    (type: PageType) => {
      return pageType === type;
    },
    [pageType]
  );

  const shouldRenderSubmitButton = useMemo(() => {
    return pageType === "create" || pageType === "edit";
  }, [pageType]);

  const shouldRenderEditButton = useMemo(() => {
    return pageType === "detail";
  }, [pageType]);

  const shouldRenderDeleteButton = useMemo(() => {
    return pageType === "detail" || pageType === "edit";
  }, [pageType]);

  const onFinish = async (values: NewsCreationForm) => {
    if (pageType === "edit") {
      setLoading(true);

      let strapiImage: StrapiImage | undefined;
      if (shouldReUpload) {
        strapiImage = await upload(values.coverImage);
      } else if (news) {
        strapiImage = news.coverImage;
      }

      updateNews(id, {
        ...values,
        coverImage: strapiImage,
      })
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Update News successfully.",
          });
          setLoading(false);
          history.push(`/news/detail/${id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to update News.",
          });
          setLoading(false);
        });
    } else {
      setLoading(true);

      const strapiImage = await upload(values.coverImage);

      createNews({
        ...values,
        coverImage: strapiImage,
      })
        .then((result) => {
          alert({
            type: "success",
            duration: 5,
            content: "Create News successfully.",
          });
          setLoading(false);
          history.push(`/news/detail/${result.id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to create News.",
          });
          setLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: "Delete News",
      content: "Are you sure you want to delete?",
      icon: <DeleteOutlined />,
      onOk() {
        setLoading(true);
        deleteNews(id).then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Delete News successfully",
          });
          setLoading(false);
          history.push("/news/list");
        });
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="News"
        items={[
          { title: "News", location: "/news/list" },
          { title: capitalize(pageType), location: "" },
        ]}
      />
      <ContentContainer>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 14,
            },
          }}
          onFinish={onFinish}
          autoComplete="off"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required.",
          }}
        >
          <Form.Item
            label="Category"
            name="pressReleaseCategory"
            rules={[{ required: true }]}
          >
            <Select
              options={categories.map((category) => ({
                label: category.title,
                value: category.id,
              }))}
              bordered={!isPage("detail")}
              disabled={isPage("detail")}
            />
          </Form.Item>
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input bordered={!isPage("detail")} readOnly={isPage("detail")} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
          >
            <TextEditor disabled={isPage("detail")} />
          </Form.Item>
          <Form.Item
            label="Cover Image"
            name="coverImage"
            rules={[{ required: true }]}
          >
            <SingleImageUploadInput
              disabled={isPage("detail")}
              onChange={() => {
                setShouldReUpload(true);
              }}
            />
          </Form.Item>
          <Form.Item label="Active" name="isActive" valuePropName="checked">
            <Switch disabled={isPage("detail")} />
          </Form.Item>
          <Form.Item
            label="Highlight"
            name="isHighlight"
            valuePropName="checked"
          >
            <Switch disabled={isPage("detail")} />
          </Form.Item>
          <Form.Item label="Tags" name="tags">
            <Select
              mode="tags"
              bordered={!isPage("detail")}
              disabled={isPage("detail")}
            />
          </Form.Item>
          <Row>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 8,
              }}
            >
              {shouldRenderSubmitButton && (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
              {shouldRenderEditButton && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/news/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                style={{ marginLeft: 5 }}
                htmlType="button"
                onClick={() => {
                  history.push("/news/list");
                }}
              >
                Cancel
              </Button>
              {shouldRenderDeleteButton && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="default"
                  onClick={handleClickDelete}
                  danger
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
