import { Button, Col, Form, Row, Spin } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useEffect, useState } from "react";
import WithAuthenticated from "../../components/WithAuthenticated";
import ContentContainer from "../../components/ContentContainer";
import Breadcrumb from "../../components/Breadcrumb";
import FooterContactFormItems from "./FooterContactSubForm";
import { getFooter, updateFooter } from "../../api/footer";
import alert from "../../components/AlertMessage";
import { Footer, FooterContact } from "../../interfaces/footer.interface";

export interface FooterSettingForm {
  contacts: FooterContact[];
}

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FooterSettingForm>();
  const [footer, setFooter] = useState<Footer>();

  const handleFormFinish = (values: FooterSettingForm) => {
    setLoading(true);
    updateFooter(values)
      .then((result) => {
        setFooter(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to update footer setting.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getFooter()
      .then((result) => {
        setFooter(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch footer setting.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (footer) {
      form.setFieldsValue(footer);
    }
  }, [footer, form]);

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="Footer Setting"
        items={[{ title: "Footer Setting", location: "" }]}
      />
      <ContentContainer>
        <Row style={{ marginBottom: 20 }}>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            span={24}
            sm={{ span: 10, offset: 14 }}
            md={{ span: 12, offset: 12 }}
          >
            <Button
              icon={<SaveOutlined />}
              type="primary"
              ghost
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} sm={16} md={14} lg={12}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={(err) => {
                if (err.errorFields[0]) {
                  form.scrollToField(err.errorFields[0].name, {
                    behavior: "smooth",
                  });
                }
              }}
              autoComplete="off"
              validateMessages={{
                // eslint-disable-next-line no-template-curly-in-string
                required: "${label} is required!",
              }}
            >
              <Form.List name="contacts">
                {(fields, { add, remove, move }) => (
                  <div>
                    <DndProvider backend={HTML5Backend}>
                      {fields.map(({ key, name }) => (
                        <FooterContactFormItems
                          key={key}
                          type="contacts"
                          id={key.toString()}
                          name={name}
                          form={form}
                          parentName={["contacts"]}
                          move={move}
                          remove={remove}
                        />
                      ))}
                    </DndProvider>
                    <Form.Item label=" " colon={false}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Footer Contact
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </Form>
          </Col>
        </Row>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
