import { Banner } from "../../interfaces/banner.interface";
import axios from "../../libs/axios";

export const getBanner = async (): Promise<Banner> => {
  const response = await axios.get<Banner>("/banner");
  return response.data;
};

export const updateBanner = async (banner: Banner): Promise<Banner> => {
  const response = await axios.put<Banner>("/banner", banner);
  return response.data;
};
