/* eslint-disable jsx-a11y/anchor-is-valid */
import { Layout, Form, Input, Image, Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import WithNonAuthenticated from "../../../components/WithNonAuthenticated";
import logo from "../../../assets/base-logo.svg";
import { ForgotPasswordForm } from "../../../interfaces/admin.interface";
import { forgotPassword } from "../../../api/admin";

const { Title } = Typography;

const Component = (): JSX.Element => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [sendEmailComplete, setEmailComplete] = useState<boolean>(false);

  const onFinish = (values: ForgotPasswordForm) => {
    forgotPassword(values).then(() => {
      setEmailComplete(true);
    });
  };
  return (
    <>
      <Layout.Content className="LoginForm">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image preview={false} width={250} src={logo} />
        </div>
        <div
          style={{
            display: sendEmailComplete ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <div style={{ width: 300 }}>
            <Title level={2} style={{ textAlign: "center" }}>
              Email sent
            </Title>
            <div
              style={{
                textAlign: "center",
                paddingBottom: 10,
              }}
            >
              It can take a few minutes to receive your password recovery link.
            </div>
            <div style={{ textAlign: "center", paddingBottom: 28 }}>
              If you do not receive this link, please contact your
              administrator.
            </div>
            <Button
              type="primary"
              block
              onClick={() => {
                history.push(`/login`);
              }}
            >
              Login
            </Button>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ display: sendEmailComplete ? "none" : "inline" }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email is required.",
              },
              {
                type: "email",
                message: "Email is not a valid format.",
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              placeholder="abc@mail.com"
              prefix="@"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Send email
            </Button>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                history.push(`/login`);
              }}
            >
              Ready to login?
            </a>
          </div>
        </Form>
      </Layout.Content>
    </>
  );
};

export default WithNonAuthenticated(Component);
