import { Button, Col, Divider, Form, Input, Row, Spin, Typography } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import WithAuthenticated from "../../components/WithAuthenticated";
import ContentContainer from "../../components/ContentContainer";
import Breadcrumb from "../../components/Breadcrumb";
import { Contact, ContactUs } from "../../interfaces/contactUs.interface";
import { getContactUs, updateContactUs } from "../../api/contactUs";
import alert from "../../components/AlertMessage";
import SingleImageUploadInput from "../../components/SingleImageUploadInput";
import urlToFile from "../../libs/urlToFile";
import ContactChannelFormItems from "../../components/ContactChannelFormItems";

export interface ContactUsForm {
  contactChannels: Contact[];
  reportChannels: Contact[];
  mapImage?: File;
  mapEmbedCode?: string;
}

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ContactUs>();
  const [form] = Form.useForm<ContactUsForm>();

  useEffect(() => {
    setLoading(true);
    getContactUs()
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch contact us.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data?.mapImage) {
      setLoading(true);
      urlToFile(data.mapImage.url, data.mapImage.name, data.mapImage.mime)
        .then((file) => {
          form.setFieldsValue({
            contactChannels: data?.contactChannels,
            reportChannels: data?.reportChannels,
            mapEmbedCode: data?.mapEmbedCode,
            mapImage: file,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      form.setFieldsValue({
        contactChannels: data?.contactChannels,
        reportChannels: data?.reportChannels,
        mapEmbedCode: data?.mapEmbedCode,
      });
    }
  }, [data, form]);

  const handleFormFinish = (values: ContactUsForm) => {
    setLoading(true);
    updateContactUs(values)
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to update contact us.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="Contact Us"
        items={[{ title: "Contact Us", location: "" }]}
      />
      <ContentContainer>
        <Row style={{ marginBottom: 20 }}>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            span={24}
            sm={{ span: 10, offset: 14 }}
            md={{ span: 12, offset: 12 }}
          >
            <Button
              icon={<SaveOutlined />}
              type="primary"
              ghost
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} sm={16} md={14} lg={12}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={(err) => {
                if (err.errorFields[0]) {
                  form.scrollToField(err.errorFields[0].name, {
                    behavior: "smooth",
                  });
                }
              }}
              autoComplete="off"
              validateMessages={{
                // eslint-disable-next-line no-template-curly-in-string
                required: "${label} is required!",
              }}
            >
              <Typography.Text>General Contacts</Typography.Text>
              <Form.List name="contactChannels">
                {(fields, { add, remove, move }) => (
                  <div>
                    <DndProvider backend={HTML5Backend}>
                      {fields.map(({ key, name }) => (
                        <ContactChannelFormItems
                          key={key}
                          type="contactChannels"
                          id={key.toString()}
                          name={name}
                          form={form}
                          parentName={["contactChannels"]}
                          move={move}
                          remove={remove}
                        />
                      ))}
                    </DndProvider>
                    <Form.Item label=" " colon={false}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add General Contact
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
              <Divider />
              <Typography.Text>Report Contacts</Typography.Text>
              <Form.List name="reportChannels">
                {(fields, { add, remove, move }) => (
                  <div>
                    <DndProvider backend={HTML5Backend}>
                      {fields.map(({ key, name }) => (
                        <ContactChannelFormItems
                          key={key}
                          type="reportChannels"
                          id={key.toString()}
                          name={name}
                          form={form}
                          parentName={["reportChannels"]}
                          move={move}
                          remove={remove}
                        />
                      ))}
                    </DndProvider>
                    <Form.Item label=" " colon={false}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Report Contact
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
              <Form.Item name="mapImage" label="Map Image">
                <SingleImageUploadInput />
              </Form.Item>
              <Form.Item name="mapEmbedCode" label="Map Embed Code">
                <Input.TextArea />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
