import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PlusOutlined } from "@ant-design/icons";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import DraggableBodyRow from "../../../components/DraggableBodyRow";
import { getBanner, updateBanner } from "../../../api/banner";
import { Banner, BannerItem } from "../../../interfaces/banner.interface";
import alert from "../../../components/AlertMessage";
import generateColumns from "./column";
import CreationModal, { BannerCreationForm } from "../CreationModal";
import { upload } from "../../../api/upload";

const ListPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [creationModalVisible, setCreationModalVisible] =
    useState<boolean>(false);
  const [banner, setBanner] = useState<Banner>({ items: [] });
  const [columns, setColumns] = useState<ColumnsType<BannerItem>>([]);

  const bannerItems = useMemo(() => {
    if (banner) {
      return banner.items;
    }
    return [];
  }, [banner]);

  const handleBannerItemChange = useCallback(
    async (items: BannerItem[]) => {
      setLoading(true);
      updateBanner({
        ...banner,
        items,
      })
        .then((result) => {
          setBanner(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update banner.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [banner]
  );

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = bannerItems[dragIndex];
      handleBannerItemChange(
        update(bannerItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [bannerItems, handleBannerItemChange]
  );

  const handleSwitchChange = useCallback(
    async (index: number, value: boolean) => {
      setLoading(true);
      const item = banner.items[index];
      item.isActive = value;
      updateBanner(banner)
        .then((result) => {
          setBanner(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update banner.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [banner]
  );

  const handleDeleteItem = useCallback(
    (index: number) => {
      const items = [...banner.items];
      if (items[index]) {
        setLoading(true);

        items.splice(index, 1);

        updateBanner({
          ...banner,
          items,
        })
          .then((result) => {
            setBanner(result);
          })
          .catch(() => {
            alert({
              type: "error",
              content: "Failed to update banner.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [banner]
  );

  const handleBannerCreationSubmit = (values: BannerCreationForm) => {
    if (
      values.files &&
      values.files.length > 0 &&
      values.files[0].originFileObj
    ) {
      setLoading(true);
      upload(values.files[0].originFileObj)
        .then(async (result) => {
          const updatedBanner = await updateBanner({
            ...banner,
            items: [
              {
                media: result,
                isActive: values.isActive,
              },
              ...banner.items,
            ],
          });
          setBanner(updatedBanner);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to add a new banner.",
          });
        })
        .finally(() => {
          setCreationModalVisible(false);
          setLoading(false);
        });
    }

    if (values.externalUrl) {
      setLoading(true);

      updateBanner({
        ...banner,
        items: [
          {
            externalUrl: values.externalUrl,
            isActive: values.isActive,
          },
          ...banner.items,
        ],
      })
        .then((result) => {
          setBanner(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to add a new banner.",
          });
        })
        .finally(() => {
          setCreationModalVisible(false);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setColumns(
      generateColumns({
        onSwitchChange: handleSwitchChange,
        onDeleteItem: handleDeleteItem,
      })
    );
  }, [handleDeleteItem, handleSwitchChange]);

  useEffect(() => {
    setLoading(true);
    getBanner()
      .then((_banner) => {
        setBanner(_banner);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch banner.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Breadcrumb title="Banner" items={[{ title: "Banner", location: "" }]} />
      <Spin spinning={loading}>
        <ContentContainer>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={{ span: 10, offset: 14 }}
              md={{ span: 12, offset: 12 }}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  setCreationModalVisible(true);
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          <DndProvider backend={HTML5Backend}>
            <Table
              style={{ overflowX: "scroll" }}
              columns={columns}
              rowKey="id"
              dataSource={bannerItems}
              pagination={false}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<unknown>;
              }}
            />
          </DndProvider>
          <CreationModal
            loading={loading}
            visible={creationModalVisible}
            onOk={handleBannerCreationSubmit}
            onCancel={() => {
              setCreationModalVisible(false);
            }}
          />
        </ContentContainer>
      </Spin>
    </>
  );
};

export default WithAuthenticated(ListPage);
