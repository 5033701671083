import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Spin, Switch, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { FC, useState } from "react";
import getBase64 from "../../libs/getBase64";
import normFile from "../../libs/normFile";

export interface CreationModalProps {
  loading: boolean;
  visible: boolean;
  onOk: (values: BannerCreationForm) => void;
  onCancel: () => void;
}

export interface BannerCreationForm {
  files?: UploadFile[];
  isActive: boolean;
  externalUrl?: string;
}

const CreationModal: FC<CreationModalProps> = ({
  loading,
  visible,
  onOk,
  onCancel,
}: CreationModalProps) => {
  const [form] = Form.useForm<BannerCreationForm>();
  const [media, setMedia] = useState<UploadFile>();

  const handleFormSubmit = (values: BannerCreationForm) => {
    onOk(values);
  };

  return (
    <Modal
      title="Add a new Banner"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel();
      }}
      confirmLoading={loading}
      cancelButtonProps={{
        loading,
      }}
      afterClose={() => {
        form.resetFields();
        setMedia(undefined);
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required!",
          }}
        >
          <Form.Item
            name="files"
            label="Media"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (
                    !getFieldValue("files") &&
                    !getFieldValue("externalUrl")
                  ) {
                    // eslint-disable-next-line no-template-curly-in-string
                    return Promise.reject(
                      new Error("You must enter the media or external URL.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Upload
              multiple={false}
              customRequest={(options) => {
                options.onSuccess?.(options.file, new XMLHttpRequest());
              }}
              onChange={(info) => {
                if (info.fileList.length > 0) {
                  setMedia(info.fileList[0]);
                } else {
                  setMedia(undefined);
                }
              }}
              listType="picture"
              className="avatar-uploader"
              accept="image/*,video/*"
              maxCount={1}
              onPreview={async (file) => {
                if (file.originFileObj) {
                  const image = new Image();
                  image.src = await getBase64(file.originFileObj);
                  const w = window.open("");
                  w?.document.write(image.outerHTML);
                }
              }}
            >
              {!media && <Button icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
          </Form.Item>
          <Form.Item
            name="externalUrl"
            label="External URL"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (
                    !getFieldValue("files") &&
                    !getFieldValue("externalUrl")
                  ) {
                    // eslint-disable-next-line no-template-curly-in-string
                    return Promise.reject(
                      new Error("You must enter the media or external URL.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="isActive"
            label="Active"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreationModal;
