import { FC, useMemo } from "react";
import { Card, Typography } from "antd";

export interface ComponentProps {
  registrationToken?: string;
}

const Component: FC<ComponentProps> = ({
  registrationToken,
}: ComponentProps): JSX.Element => {
  const invitationURL = useMemo(() => {
    if (registrationToken) {
      return `${window.location.origin}/invitation?registrationToken=${registrationToken}`;
    }
    return null;
  }, [registrationToken]);

  if (!invitationURL) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        bordered
        style={{
          width: "70%",
          padding: 0,
          marginBottom: 12,
          borderLeftStyle: "solid",
          borderLeftColor: "#1890ff",
          borderLeftWidth: "thick",
        }}
      >
        <Typography.Text strong copyable>
          {invitationURL}
        </Typography.Text>
        <div>
          <Typography.Text type="secondary">
            Send this link to the user for them to connect.
          </Typography.Text>
        </div>
      </Card>
    </div>
  );
};

export default Component;
