import { Contact, ContactUs } from "../../interfaces/contactUs.interface";
import axios from "../../libs/axios";

export interface UpdateContactUsData {
  contactChannels: Contact[];
  reportChannels: Contact[];
  mapImage?: File;
  mapEmbedCode?: string;
}

export const getContactUs = async (): Promise<ContactUs> => {
  const response = await axios.get<ContactUs>("/contact-us");
  return response.data;
};

export const updateContactUs = async (
  data: UpdateContactUsData
): Promise<ContactUs> => {
  const formData = new FormData();

  const { mapImage, ...restValues } = data;

  formData.append("data", JSON.stringify(restValues));

  if (mapImage) {
    formData.append("files.mapImage", mapImage);
  }

  const response = await axios.put<ContactUs>("/contact-us", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};
