import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Spin, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import DraggableBodyRow from "../../../components/DraggableBodyRow";
import { FAQ, FAQGroup } from "../../../interfaces/faq.interface";
import generateColumns from "./column";
import { getFAQ, updateFAQ } from "../../../api/faq";
import alert from "../../../components/AlertMessage";
import CreationModal, { FAQCreationForm } from "./CreationModal";

const ListPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [creationModalVisible, setCreationModalVisible] =
    useState<boolean>(false);
  const [faq, setFaq] = useState<FAQ>({ groups: [] });

  const faqGroups = useMemo<FAQGroup[]>(() => {
    return faq?.groups || [];
  }, [faq]);

  const handleFaqGroupChange = useCallback(
    (groups: FAQGroup[]) => {
      setLoading(true);
      updateFAQ({
        ...faq,
        groups,
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update FAQ.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [faq]
  );

  const handleDeleteGroup = useCallback(
    (index: number) => {
      const groups = [...faq.groups];
      if (groups[index]) {
        setLoading(true);

        groups.splice(index, 1);

        updateFAQ({
          ...faq,
          groups,
        })
          .then((result) => {
            setFaq(result);
          })
          .catch(() => {
            alert({
              type: "error",
              content: "Failed to update FAQ.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [faq]
  );

  const handleSwitchChange = useCallback(
    async (index: number, value: boolean) => {
      setLoading(true);
      const group = faq.groups[index];
      group.isActive = value;
      updateFAQ(faq)
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to update FAQ.",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [faq]
  );

  const handleClickGroupRecord = useCallback(
    (index: number) => {
      history.push(`/faq/question/${index}`);
    },
    [history]
  );

  const handleFAQCreationSubmit = useCallback(
    (values: FAQCreationForm) => {
      setLoading(true);
      updateFAQ({
        ...faq,
        groups: [
          {
            ...values,
            questions: [],
          },
          ...faq.groups,
        ],
      })
        .then((result) => {
          setFaq(result);
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to add new FAQ.",
          });
        })
        .finally(() => {
          setCreationModalVisible(false);
          setLoading(false);
        });
    },
    [faq]
  );

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = faqGroups[dragIndex];
      handleFaqGroupChange(
        update(faqGroups, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [faqGroups, handleFaqGroupChange]
  );

  const columns = useMemo(() => {
    return generateColumns({
      onClickItem: handleClickGroupRecord,
      onSwitchChange: handleSwitchChange,
      onDeleteItem: handleDeleteGroup,
    });
  }, [handleClickGroupRecord, handleDeleteGroup, handleSwitchChange]);

  useEffect(() => {
    setLoading(true);
    getFAQ()
      .then((result) => {
        setFaq(result);
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch FAQ.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Breadcrumb
        title="FAQ"
        items={[{ title: "FAQ", location: "" }]}
        capitalizeTitle={false}
      />
      <Spin spinning={loading}>
        <ContentContainer>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={{ span: 10, offset: 14 }}
              md={{ span: 12, offset: 12 }}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  setCreationModalVisible(true);
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          <DndProvider backend={HTML5Backend}>
            <Table
              style={{ overflowX: "scroll" }}
              columns={columns}
              rowKey="id"
              dataSource={faqGroups}
              pagination={false}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<unknown>;
              }}
            />
          </DndProvider>
          <CreationModal
            loading={loading}
            visible={creationModalVisible}
            onOk={handleFAQCreationSubmit}
            onCancel={() => {
              setCreationModalVisible(false);
            }}
          />
        </ContentContainer>
      </Spin>
    </>
  );
};

export default WithAuthenticated(ListPage);
