import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
  Row,
  Col,
  Select,
  Switch,
} from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import alert from "../../../components/AlertMessage";
import WithAuthenticated from "../../../components/WithAuthenticated";
import ContentContainer from "../../../components/ContentContainer";
import Breadcrumb from "../../../components/Breadcrumb";
import TextEditor from "../../../components/TextEditor";
import SingleImageUploadInput from "../../../components/SingleImageUploadInput";
import urlToFile from "../../../libs/urlToFile";
import { upload } from "../../../api/upload";
import { StrapiImage } from "../../../interfaces/strapi.interface";
import { Announcement } from "../../../interfaces/announcement.interface";
import {
  createAnnouncement,
  deleteAnnouncement,
  getAnnouncementById,
  updateAnnouncement,
} from "../../../api/announcement";

export type PageType = "create" | "edit" | "detail";

export interface AnnouncementCreationForm {
  title: string;
  description: string;
  coverImage?: File;
  tags?: string[];
  isActive: boolean;
}

const Page = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<Record<"id", string>>();
  const [form] = Form.useForm<AnnouncementCreationForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [announcement, setAnnouncement] = useState<Announcement>();
  const [shouldReUpload, setShouldReUpload] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);

      getAnnouncementById(id)
        .then(async (result) => {
          setAnnouncement(result);
          let file: File | undefined;
          if (result.coverImage) {
            file = await urlToFile(
              result.coverImage.url,
              result.coverImage.name,
              result.coverImage.mime
            );
          }
          form.setFieldsValue({
            title: result.title,
            description: result.description,
            isActive: result.isActive,
            tags: result.tags || [],
            coverImage: file,
          });
        })
        .catch(() => {
          alert({
            type: "error",
            content: "Failed to fetch data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form, id]);

  const pageType: PageType = useMemo(() => {
    if (location.pathname === `/announcement/detail/${id}`) return "detail";
    if (location.pathname === `/announcement/edit/${id}`) return "edit";
    return "create";
  }, [location, id]);

  const isPage = useCallback(
    (type: PageType) => {
      return pageType === type;
    },
    [pageType]
  );

  const shouldRenderSubmitButton = useMemo(() => {
    return pageType === "create" || pageType === "edit";
  }, [pageType]);

  const shouldRenderEditButton = useMemo(() => {
    return pageType === "detail";
  }, [pageType]);

  const shouldRenderDeleteButton = useMemo(() => {
    return pageType === "detail" || pageType === "edit";
  }, [pageType]);

  const onFinish = async (values: AnnouncementCreationForm) => {
    if (pageType === "edit") {
      setLoading(true);

      let strapiImage: StrapiImage | undefined;
      if (values.coverImage && shouldReUpload) {
        strapiImage = await upload(values.coverImage);
      } else if (!values.coverImage && shouldReUpload) {
        strapiImage = undefined;
      } else if (announcement) {
        strapiImage = announcement.coverImage;
      }

      updateAnnouncement(id, {
        ...values,
        coverImage: strapiImage || null,
      })
        .then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Update Announcement successfully.",
          });
          setLoading(false);
          history.push(`/announcement/detail/${id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to update Announcement.",
          });
          setLoading(false);
        });
    } else {
      setLoading(true);

      let strapiImage: StrapiImage | undefined;
      if (values.coverImage) {
        strapiImage = await upload(values.coverImage);
      }

      createAnnouncement({
        ...values,
        coverImage: strapiImage,
      })
        .then((result) => {
          alert({
            type: "success",
            duration: 5,
            content: "Create Announcement successfully.",
          });
          setLoading(false);
          history.push(`/announcement/detail/${result.id}`);
        })
        .catch(() => {
          alert({
            type: "error",
            duration: 5,
            content: "Failed to create Announcement.",
          });
          setLoading(false);
        });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: "Delete Announcement",
      content: "Are you sure you want to delete?",
      icon: <DeleteOutlined />,
      onOk() {
        setLoading(true);
        deleteAnnouncement(id).then(() => {
          alert({
            type: "success",
            duration: 5,
            content: "Delete Announcement successfully",
          });
          setLoading(false);
          history.push("/announcement/list");
        });
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb
        title="Announcement"
        items={[
          { title: "Announcement", location: "/announcement/list" },
          { title: capitalize(pageType), location: "" },
        ]}
      />
      <ContentContainer>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 14,
            },
          }}
          onFinish={onFinish}
          autoComplete="off"
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required.",
          }}
        >
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input bordered={!isPage("detail")} readOnly={isPage("detail")} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true }]}
          >
            <TextEditor disabled={isPage("detail")} />
          </Form.Item>
          <Form.Item label="Cover Image" name="coverImage">
            <SingleImageUploadInput
              disabled={isPage("detail")}
              onChange={() => {
                setShouldReUpload(true);
              }}
            />
          </Form.Item>
          <Form.Item label="Active" name="isActive" valuePropName="checked">
            <Switch disabled={isPage("detail")} />
          </Form.Item>
          <Form.Item label="Tags" name="tags">
            <Select
              mode="tags"
              bordered={!isPage("detail")}
              disabled={isPage("detail")}
            />
          </Form.Item>
          <Row>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 8,
              }}
            >
              {shouldRenderSubmitButton && (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
              {shouldRenderEditButton && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/announcement/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                style={{ marginLeft: 5 }}
                htmlType="button"
                onClick={() => {
                  history.push("/announcement/list");
                }}
              >
                Cancel
              </Button>
              {shouldRenderDeleteButton && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="default"
                  onClick={handleClickDelete}
                  danger
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </Spin>
  );
};

export default WithAuthenticated(Page);
