import { UploadChangeParam, UploadFile } from "antd/es/upload/interface";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const normFile = (e: UploadChangeParam<UploadFile<unknown>>) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export default normFile;
