import {
  Table,
  Input,
  Space,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Typography,
  Badge,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { blue, red } from "@ant-design/colors";
import cloneDeep from "lodash/cloneDeep";
import removeInArray from "lodash/remove";
import type { ColumnsType } from "antd/lib/table/interface";
import alert from "../../../components/AlertMessage";
import type { Admin } from "../../../interfaces/admin.interface";
import { getAdmins, deleteAdmin } from "../../../api/admin";
import WithAuthenticated from "../../../components/WithAuthenticated";
import useAuthentication from "../../../hooks/useAuthentication";
import Breadcrumb from "../../../components/Breadcrumb";
import ContentContainer from "../../../components/ContentContainer";
import ContentContainerNoRole from "../../../components/ContentContainerNoRole";

const { Search } = Input;

const Container = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<Array<Admin>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const authentication = useAuthentication();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAdmins({
        pageSize,
        page: currentPage,
      }),
    ])
      .then(async ([list]) => {
        setDataSource(list.data.results);
        setPageCount(list.data.pagination.pageCount);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage, pageSize]);

  const authenticationId = useMemo(() => {
    return Number(authentication?.user?.id);
  }, [authentication]);

  let Content = ContentContainer;

  if (authentication?.user?.roles[0].name !== "Super Admin") {
    Content = ContentContainerNoRole;
  }

  const columns: ColumnsType<Admin> = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      render: (text: string, record: Admin) => (
        <Typography.Text
          style={{ color: blue.primary, cursor: "pointer" }}
          onClick={() => {
            history.push(`/admin/detail/${record.id}`);
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Role",
      key: "roles",
      render: (_, record: Admin) => (
        <>
          <div>
            {record.roles[0].name === "Super Admin" ? "Admin" : "Staff"}
          </div>
        </>
      ),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Status",
      key: "status",
      render: (_, record: Admin) => (
        <>
          <Badge
            style={{
              display: record.registrationToken === null ? "none" : "block",
            }}
            status="warning"
            text="Unconfirmed"
          />
          <Badge
            style={{
              display: record.isActive ? "block" : "none",
            }}
            status="success"
            text="active"
          />
          <Badge
            style={{
              display:
                record.registrationToken === null && !record.isActive
                  ? "block"
                  : "none",
            }}
            status="error"
            text="Inactive"
          />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: Admin, index: number) => (
        <Space
          size="middle"
          style={{ display: authenticationId === record.id ? "none" : "" }}
        >
          <EditOutlined
            style={{ color: blue.primary }}
            onClick={() => {
              history.push({
                pathname: `/admin/edit/${record.id}`,
              });
            }}
          />
          <DeleteOutlined
            onClick={() => {
              Modal.confirm({
                title: "Delete Administrator",
                content: `Are you sure you want to delete?`,
                icon: <DeleteOutlined />,
                onOk() {
                  setLoading(true);
                  deleteAdmin({ ids: [record.id] }).then(() => {
                    const cloneDataSource = cloneDeep(dataSource);
                    removeInArray(cloneDataSource, (__, idx) => {
                      return index === idx;
                    });
                    setDataSource(cloneDataSource);
                    alert({
                      type: "success",
                      duration: 5,
                      content: "Delete administrator successfully",
                    });
                    setLoading(false);
                  });
                },
              });
            }}
            style={{ color: red.primary }}
          />
        </Space>
      ),
    },
  ];

  const onSearch = (value: string) => {
    setLoading(true);
    getAdmins({
      pageSize,
      page: 1,
      _q: value,
    })
      .then(async (result) => {
        setDataSource(result.data.results);
        setPageCount(result.data.pagination.pageCount);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb
        title="Administrator"
        items={[{ title: "Administrator", location: "" }]}
      />
      <Spin spinning={loading}>
        <Content>
          <Row style={{ marginBottom: 20 }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
              span={24}
              sm={14}
              md={12}
            >
              <Search
                placeholder="Please Enter"
                allowClear
                enterButton="Search"
                onSearch={onSearch}
              />
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "flex-end" }}
              span={24}
              sm={10}
              md={12}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  history.push(`/admin/create`);
                }}
              >
                Create Admin
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              total: pageCount * 10,
              pageSize,
              onChange: (page, pagesize) => {
                setCurrentPage(page);
                setPageSize(pagesize || 0);
              },
            }}
            style={{ overflowX: "scroll" }}
          />
        </Content>
      </Spin>
    </>
  );
};

export default WithAuthenticated(Container);
