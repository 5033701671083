import { useEffect, useState } from "react";
import { Spin, Form, Input, Row, Col, Button, Typography, Divider } from "antd";
import { ProfileForm } from "../../../interfaces/profile.interface";
import { getProfile, updateProfile } from "../../../api/profile";
import { GetProfileUser } from "../../../interfaces/profileAPI.interface";
import alert from "../../AlertMessage";

const Component = (): JSX.Element => {
  const [form] = Form.useForm<ProfileForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileForm>();

  useEffect(() => {
    setLoading(true);
    getProfile()
      .then((res: GetProfileUser) => {
        setProfile({
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          email: res.data.email,
        });
      })
      .catch(() => {
        alert({
          type: "error",
          content: "Failed to fetch profile",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        firstname: profile.firstname,
        lastname: profile.lastname,
        email: profile.email,
      });
    }
  }, [profile, form]);

  const handleSubmitForm = () => {
    setLoading(true);
    updateProfile({
      firstname: form.getFieldValue("firstname"),
      lastname: form.getFieldValue("lastname"),
    })
      .then((result: GetProfileUser) => {
        setProfile({
          firstname: result.data.firstname,
          lastname: result.data.lastname,
          email: result.data.email,
        });
        alert({
          type: "success",
          content: "Change profile successfully",
        });
        setCanEdit(false);
      })
      .catch((error) => {
        alert({
          type: "error",
          content: error,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="Profile">
      <Spin spinning={loading}>
        <Typography.Title level={5}>Details</Typography.Title>
        <Divider />
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{
            xs: 24,
            sm: {
              span: 8,
            },
          }}
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required!",
          }}
          autoComplete="off"
        >
          <Form.Item
            name="firstname"
            label="First Name"
            rules={[{ required: true, message: "First name is required." }]}
          >
            {canEdit ? (
              <Input placeholder="Please enter your firstname" />
            ) : (
              <Typography.Text>{profile?.firstname}</Typography.Text>
            )}
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[{ required: true, message: "Last name is required." }]}
          >
            {canEdit ? (
              <Input placeholder="Please enter your lastname" />
            ) : (
              <Typography.Text>{profile?.lastname}</Typography.Text>
            )}
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Email is required." }]}
          >
            <Typography.Text>{profile?.email}</Typography.Text>
          </Form.Item>
          <Row>
            <Col
              xs={24}
              sm={{
                span: 16,
                offset: 8,
              }}
            >
              {canEdit ? (
                <>
                  <Button type="primary" onClick={handleSubmitForm}>
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    type="default"
                    onClick={() => {
                      setCanEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    setCanEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default Component;
